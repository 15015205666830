export const conditionsField = [
    {
       field:"name",
       operators:[
          "equal",
          "contains",
          "doesNotContain",
          "startsWith",
          "endsWith"
       ]
    },
    {
       field:"currentPoints",
       operators:[
          "equal",
          "notEqual",
          "greaterThan",
          "greaterThanOrEqual",
          "lessThan",
          "lessThanOrEqual"
       ]
    },
    {
        field:"joinDate",
        operators:[
          "equal",
          "greaterThan",
          "greaterThanOrEqual",
          "lessThan",
          "lessThanOrEqual"
       ]
    },
    {
        field:"lastScannedCodeDate",
        operators:[
          "equal",
          "greaterThan",
          "greaterThanOrEqual",
          "lessThan",
          "lessThanOrEqual"
       ]
    },
    {
        field:"lastRewardsRedeemedDate",
        operators:[
          "equal",
          "greaterThan",
          "greaterThanOrEqual",
          "lessThan",
          "lessThanOrEqual"
       ]
    },
    {
        field:"lastTransactionDate",
        operators:[
          "equal",
          "greaterThan",
          "greaterThanOrEqual",
          "lessThan",
          "lessThanOrEqual"
       ]
    },
    {
        field:"email",
        operators:[
          "equal",
          "notEqual",
          "contains",
          "doesNotContain"
       ]
    },
    {
        field:"lastActivityDate",
        operators:[
          "equal",
          "greaterThan",
          "greaterThanOrEqual",
          "lessThan",
          "lessThanOrEqual"
       ]
    },
    {
        field:"lifetimePoints",
        operators:[
          "equal",
          "notEqual",
          "greaterThan",
          "greaterThanOrEqual",
          "lessThan",
          "lessThanOrEqual"
       ]
    },
    // {
    //     field:"tags",
    //     operators:[
    //       "in"
    //    ]
    // }
 ]

 export const allowedSuffixTagCharacters = [
    '.',
    ',',
    '!',
    '$',
    '%',
    '&',
    '(',
    ')',
    '*',
    '+',
    '-',
    '/',
    ':',
    ';',
    '<',
    '=',
    '>',
    '?',
    '[',
    ']',
    '{',
    '|',
    '}',
    '~',
  ];

  export const listTag = [
    {
      name: 'Customer name',
      value: 'CUSTOMER_NAME',
    },
    {
      name: 'Customer First name',
      value: 'CUSTOMER_FIRST_NAME',
    },
    {
      name: 'Business name',
      value: 'BUSINESS_NAME',
    },
    {
      name: 'Business get started URL',
      value: 'BUSINESS_GET_STARTED_URL',
    },
    {
      name: 'Business address',
      value: 'BUSINESS_ADDRESS',
    },
    {
      name: 'Customer points',
      value: 'CUSTOMER_POINTS',
    },
    {
      name: 'Voucher title',
      value: 'VOUCHER_TITLE',
    },
    {
      name: 'Voucher description (EMAIL, APP)',
      value: 'VOUCHER_DESCRIPTION',
    },
    {
      name: 'Voucher link',
      value: 'VOUCHER_LINK',
    },
    {
      name: 'Voucher start date',
      value: 'VOUCHER_START_DATE',
    },
    {
      name: 'Voucher end date',
      value: 'VOUCHER_END_DATE',
    },
    {
      name: 'Customer lifetime points',
      value: 'CUSTOMER_LIFETIME_POINTS',
    },
    {
      name: 'Download app URL',
      value: 'DOWNLOAD_APP_URL',
    },
  ];

  export const listTagCampaing = [
    {
      name: 'Customer name',
      value: 'CUSTOMER_NAME',
    },
    {
      name: 'Customer First name',
      value: 'CUSTOMER_FIRST_NAME',
    },
    {
      name: 'Business name',
      value: 'BUSINESS_NAME',
    },
    {
      name: 'Business get started URL',
      value: 'BUSINESS_GET_STARTED_URL',
    },
    {
      name: 'Business address',
      value: 'BUSINESS_ADDRESS',
    },
    {
      name: 'Customer points',
      value: 'CUSTOMER_POINTS',
    },
    {
      name: 'Customer lifetime points',
      value: 'CUSTOMER_LIFETIME_POINTS',
    },
    {
      name: 'Download app URL',
      value: 'DOWNLOAD_APP_URL',
    },
  ];

  export const listTagCampaignOffers = [
    {
      name: 'Customer name',
      value: 'CUSTOMER_NAME',
    },
    {
      name: 'Customer First name',
      value: 'CUSTOMER_FIRST_NAME',
    },
    {
      name: 'Business name',
      value: 'BUSINESS_NAME',
    },
    {
      name: 'Business get started URL',
      value: 'BUSINESS_GET_STARTED_URL',
    },
    {
      name: 'Business address',
      value: 'BUSINESS_ADDRESS',
    },
    {
      name: 'Customer points',
      value: 'CUSTOMER_POINTS',
    },
    {
      name: 'Offer code',
      value: 'OFFER_CODE',
    },
    {
      name: 'Offer title',
      value: 'OFFER_TITLE',
    },
    {
      name: 'Offer description (EMAIL, APP)',
      value: 'OFFER_DESCRIPTION',
    },
    {
      name: 'Offer link',
      value: 'OFFER_LINK',
    },
    {
      name: 'Offer Qr  (EMAIL, APP)',
      value: 'OFFER_QR',
    },
    {
      name: 'Offer start date',
      value: 'OFFER_START_DATE',
    },
    {
      name: 'Offer end date',
      value: 'OFFER_END_DATE',
    },
    {
      name: 'Customer lifetime points',
      value: 'CUSTOMER_LIFETIME_POINTS',
    },
  ];

  export const contentTemplate = [
    {
        "name": "manual",
        "des": "manualDes"
    },
    {
        "name": "rewardsredeem",
        "des": "rewardsredeemDes"
    }
  ];

  export const conditionsFieldTemp = [
    {
      name: 'lastActivity7Date',
      value: 'lastTransactionDate',
    },
    {
      name: 'redeemedinthelast14days',
      value: 'lastRewardsRedeemedDate',
    },
    {
      name: 'redeemablerewardsworth50points',
      value: 'currentPoints',
    }
  ];

  export const conditionsFieldCustom = [
    {
      field: 'name',
    },
    {
      field: 'currentPoints',
    },
    {
      field: 'joinDate',
    },
    {
      field: 'lastScannedCodeDate',
    },
    {
      field: 'lastRewardsRedeemedDate',
    },
    {
      field: 'lastTransactionDate',
    },
    {
      field: 'email',
    },
    {
      field: 'lastActivityDate',
    },
    {
      field: 'lifetimePoints',
    },
  ]