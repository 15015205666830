import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from 'app/app.component';
import { appConfig } from 'app/app.config';
import * as Sentry from '@sentry/angular-ivy';
import { enableProdMode } from '@angular/core';
import { environment } from 'environments/environment';

enableProdMode();
// bootstrapApplication(AppComponent, {
//   providers: [
//     provideNgxStripe()
//   ]
// });


Sentry.init({
  dsn: environment?.sentry?.dsn,
  integrations: [Sentry.browserTracingIntegration()],
  
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
