import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appMaxValue]'
})
export class MaxValueDirective {
  @Input() maxValue!: number;

  constructor(private el: ElementRef, private control: NgControl) {}

  @HostListener('input', ['$event'])
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    let value = parseInt(input.value, 10);

    if (isNaN(value)) {
      value = 0;
    }

    if (value > this.maxValue) {
      value = this.maxValue;
    }

    this.control.control?.setValue(value);
  }
}