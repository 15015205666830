import { eachDayOfInterval } from 'date-fns';
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { BusinessSARepository } from './business-supper-admin.repository';
import { API, APIService } from '@sticky/common';
import {
  addEntities,
  updateEntities,
  upsertEntities,
} from '@ngneat/elf-entities';
import { Observable, of, tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { BusinessSA, DashboardSupport } from './business-supper-admin.model';
import { AuthRepository } from '../auth';
import { AppRepository } from '../app';

/**
 * Đây là based service dành cho xử lý chung
 */
@Injectable({ providedIn: 'root' })
export class BusinessSAService {
  constructor(
    private readonly api: APIService,
    private store: BusinessSARepository,
    private readonly authstore: AuthRepository,
    private appRepo: AppRepository,
  ) {}

  getAllBusiness(params?: any): Observable<BusinessSA> {
    let pa = new HttpParams()
    // let pa = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.api.get<any>(`${API.BUSINESS_SUPPORT}`,{
      params: pa
    }).pipe(
      tap((res) => {
        this.store.store.update(upsertEntities(res));
      })
    );
  }

  getBusinessSupportDetails(bid?: string, params?: any): Observable<BusinessSA> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function(key) {
        pa = pa.append(key, params[key])
      })
    }
    return this.api.patch<any>(`${API.BUSINESS_SUPPORT}/${bid}/switch`,{
      params: pa
    }).pipe(
      tap((data) => {
        if (data?.accessToken) {
          // Save vào store
          this.authstore.update({
            accessToken: data?.accessToken,
            refreshToken: data?.refreshToken,
            userId: data?.userId,
            isSessionTimeout: false,
            role: data?.role,
            supportRole: data?.supportRole,
          });
        }
        const business = data?.business;
        this.appRepo.update({
          business: business,
          currentBusinessId: business?.id,
          currentUserId: data?.userId,
          displayName: data?.displayName,
          phone: data?.phone,
          email: data?.email,
        });
      })
    );
  }

  getDashboardScannedCodes(type?:string,params?: any,): Observable<DashboardSupport> {
    let pa = new HttpParams().set('type','TODAY')
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<DashboardSupport>(`${API.DASHBOARD_SCANNED_CODES}`, { params: pa })
      .pipe(
        tap((data) => {
          if (data) {
            // this.store.update(data);
            this.store.update({
              scanned_codes: data,
            });
          }
        })
      );
  }
  getDashboardScannedCodesMonth(type?:string,params?: any,): Observable<DashboardSupport> {
    let pa = new HttpParams().set('type','THIS_MONTH')
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<DashboardSupport>(`${API.DASHBOARD_SCANNED_CODES}`, { params: pa })
      .pipe(
        tap((data) => {
          if (data) {
            // this.store.update(data);
            this.store.update({
              scanned_codes_month: data,
            });
          }
        })
      );
  }

  getDashboardRewardsRedeemed(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<any>(`${API.DASHBOARD_REWARDS_REDEEMED}`, {
        params: pa,
      })
      .pipe(
        tap((data) => {
          this.store.update({
            rewards_redeemed: data,
          });
        })
      );
  }

  getDashboardGivenPoints(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<any>(`${API.DASHBOARD_GIVEN_POINTS}`, {
        params: pa,
      })
      .pipe(
        tap((data) => {
          this.store.update({
            given: data,
          });
        })
      );
  }

  getDashboardNoCustomers(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<any>(`${API.DASHBOARD_NO_CUSTOMERS}`, {
        params: pa,
      })
      .pipe(
        tap((data) => {
          this.store.update({
            no_customers: data,
          });
        })
      );
  }
  getDashboardBusinessStats(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<any>(`${API.DASHBOAR_BUSINESS_STATS}`, {
        params: pa,
      })
      .pipe(
        tap((data) => {
          this.store.update({
            business_stats: data,
          });
        })
      );
  }

  getDashboardBusinessNewReturn(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<any>(`${API.DASHBOARD_BUSINESS_NEW_RETURN}`, {
        params: pa,
      })
      .pipe(
        tap((data) => {
          this.store.update({
            business_new_return: data,
          });
        })
      );
  }

  getDashboardBusinessStatsReferral(params?: any): Observable<any> {
    let pa = new HttpParams()
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<any>(`${API.DASHBOAR_BUSINESS_STATS_REFERRAL}`, {
        params: pa,
      })
      .pipe(
        tap((data) => {
          this.store.update({
            business_stats_referral: data,
          });
        })
      );
  }


  getBusinessCharts(
    month?: number,
    year?: number,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams().set('month', month).set('year', year);
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<any>(`${API.DASHBOAR_CHART}`, {
        params: pa,
      })
      .pipe(
        tap((data) => {
          const res = {
            codesScanned: data.codesScanned,
            rewardsRedeemed: data.rewardsRedeemed,
          };
          this.store.update({
            code_rewards: res,
          });
        })
      );
  }

  getBusinessChartsCustomers(
    month?: number,
    year?: number,
    params?: any
  ): Observable<any> {
    let pa = new HttpParams().set('month', month).set('year', year);
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<any>(`${API.DASHBOAR_CHART}`, {
        params: pa,
      })
      .pipe(
        tap((data) => {
          const res = {
            newCustomers: data.newCustomers,
            returningCustomers: data.returningCustomers,
          };
          this.store.update({
            new_return_guest: res,
          });
        })
      );
  }
  
}
