type P_PAGES = 'CAMPAIGN' | 'OFFER';
type P_ACTIONS = 'FULL' | 'VIEW' | 'ADD' | 'DELETE' | 'UPDATE'

interface Permission {
  page: P_PAGES;
  actions: P_ACTIONS[];
}

// DEFINE ALL PAGE CAN DO ...
const PERMISSIONS: Permission[] = [
  {
    page: 'OFFER',
    actions: ['FULL'],
  }
]

export function canDo(page: P_PAGES, actions: P_ACTIONS[]): boolean {
  const pPage = PERMISSIONS.find(p=>p.page === page);
  if (!pPage || actions.length === 0) {
    return false;
  }
  const pPageActions = pPage.actions;
  for(let i=0; i<actions.length; i++) {
    const action = actions[i];
    return pPageActions.some(e=>e===action);
  }
  return true;
}

export function processDetailPermision(page: P_PAGES, inputCompare: any): boolean {
  switch(page) {
    case 'OFFER':
      const allowUpdateOfferStatus = ['DRAFT', 'ACTIVE'];
      return allowUpdateOfferStatus.some(e=>e===inputCompare);
    case 'CAMPAIGN':
      const allowUpdateCampaignStatus = ['DRAFT','QUEUED','PAUSED'];
      return allowUpdateCampaignStatus.some(e=>e===inputCompare);

    default: 
      return true;
  }

}