import { Currency } from '@sticky/util-common';
import { Currencies } from './currencies.config';

export class CurrencyHelper {
  static getCurrency(code: string): Currency {
    return Currencies.find((f) => f.code.toLowerCase() === code.toLowerCase());
  }

  static getCurrencyDisplay(id = 'usd', displayBy: 'symbol' | 'code'): string {
    let curr = this.getCurrency(id);
    if (!curr) {
      curr = Currencies[0];
    }
    switch (displayBy) {
      case 'symbol':
        return curr.symbol;
      default:
        return curr.code;
    }
  }

  static isCurrencyPrefix(id: string): boolean {
    const curr = this.getCurrency(id);
    if (curr) {
      return curr.prefix;
    }
    return null;
  }
}
