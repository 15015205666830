import { LangDefinition } from '@ngneat/transloco';

export const LanguagesSupport: LangDefinition[] = [
    {
        id: 'vi',
        label: 'Tiếng Việt'
      }, {
        id: 'en',
        label: 'English'
      }
];
