import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterLink } from '@angular/router';
import {
  TRANSLOCO_SCOPE,
  TranslocoModule,
} from '@ngneat/transloco';
import { LanguagesSupport } from '@sticky/config';
import { Logger } from '@sticky/services';
import { AppRepository } from '@sticky/state/app';
import { PipesModule } from '@sticky/util-common/pipes.module';
import { PhoneNumberComponentModule } from 'app/components/phone-number/phone-number.component';
const log = new Logger('OffersTypesDialogComponent');

const loader = LanguagesSupport.reduce((acc: any, lang) => {
  acc[lang.id] = () => import(`./i18n/${lang.id}.json`);
  return acc;
}, {});

@Component({
  selector: 'time-zone-dialog',
  templateUrl: './time-zone-dialog.component.html',
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'timeZoneDialog',
        loader,
      },
    },
  ],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .time-zone-dialog {
         .mdc-dialog__surface {
          padding: 24px !important;
        }
        max-width: 88vw !important;
        min-width: 344px !important;
        @media screen and (max-width: 375px) {
          min-width: 320px !important;
          }
       
      }
    `,
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatSidenavModule,
    MatOptionModule,
    PipesModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    RouterLink,
    TranslocoModule,
    PhoneNumberComponentModule,
    MatTooltipModule
  ],
})
export class TimeZoneDialogComponent implements OnInit {
  submitted: boolean = false;
  typeParams: string;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<TimeZoneDialogComponent>,
    private appRepo: AppRepository,
  ) {
    this.typeParams = data
  }
  ngOnInit(): void {
   
  }

  get businessTimeZone() {
    return this.appRepo?.business?.timeZone;
  }

  onCloseDialog(){
    this.dialogRef.close()
  }
  
}
