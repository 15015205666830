import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  getAllEntities,
  getEntity,
  selectAllEntitiesApply,
  withEntities,
} from '@ngneat/elf-entities';
import { Audit } from './audit.model';

const store = createStore(
  { name: 'audit' },
  withEntities<Audit>()
);

@Injectable({ providedIn: 'root' })
export class AuditRepository {
  constructor() {}

  audit$ = store.pipe(selectAllEntitiesApply({}));

  update(audit: Audit) {
    store.update((state) => ({ ...state, ...audit }));
  }

  get store() {
    return store;
  }
}
