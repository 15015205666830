/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import { API, APIService } from '@sticky/common';
import { Observable, of, tap } from 'rxjs';
import { HttpParams } from '@angular/common/http';
import { AuditDTO, AuditUpdateDTO } from './audit.dto';
import { environment } from 'environments/environment';
import { AppRepository } from '../app';
import { TranslocoService } from '@ngneat/transloco';

/**
 * Đây là based service dành cho xử lý chung
 */
@Injectable({ providedIn: 'root' })
export class AuditService {
  private data: any;
  locale: string;
  constructor(
    private readonly api: APIService,
    public appRepo: AppRepository,
    private translate: TranslocoService,
  ) {
    this.translate.langChanges$.subscribe((data) => {
      this.locale = data;
    });
  }

  checkAudit(params?: any): Observable<AuditDTO> {
    let pa = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    if (!this.data) {
      return this.api
      .get<any>(`${API.AUDIT}`, {
        params: pa,
      })
      .pipe(
        tap((response) => {
          this.data = response;
        })
      );
    } else {
      return of(this.data);
    }
  }

  sendAudit(data: AuditUpdateDTO): Observable<AuditDTO> {
    const os = this.getDetectionOs();
    const allowAudit = this.data?.allow;
    const body = {
      environment: environment?.name,
      os: os,
      platform: 'stickyqr-bo',
      lang: this.locale,
      isLogged: this.appRepo?.uid ? true : false,
      uid: this.appRepo?.uid,
      bid: this.appRepo?.business?.id,
      phone: this.appRepo?.phone,
      email: this.appRepo?.email,
      pageUrl: data?.pageUrl,
      pageName: data?.pageName,
      pageAction: data?.pageAction
    }
    try {
      if (allowAudit) {
        const observable = this.api.post<AuditDTO>(API.AUDIT, { body: body });
        if (observable) {
          return observable.pipe(
            tap((response) => {
              // console.log("sendAudit", response)
            })
          );
        } else {
          return of(null);
        }
      } else {
        return of(null);
      }
    } catch (error) {
      return of(null);
    }
  }

  private getDetectionOs() {
    if (typeof window !== 'undefined') {
      const userAgent = navigator.userAgent;
      // android detection
      if (/android/i.test(userAgent)) {
        return 'android';
      }
      // iOS detection
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'ios';
      }
      return 'web';
    }
  }
}
