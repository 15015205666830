/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { BusinessRepository } from './business.repository';
import { Business } from './business.model';
import { API, APIService } from '@sticky/common';
import { Observable, tap } from 'rxjs';
import { updateEntities, upsertEntities } from '@ngneat/elf-entities';
import { BusinessUpdateDTO, ReferralProgramUpdateDTO } from './business.dto';
import { AppRepository } from '../app';

/**
 * Đây là based service dành cho xử lý chung
 */
@Injectable({ providedIn: 'root' })
export class BusinessService {
  constructor(
    private readonly api: APIService,
    private store: BusinessRepository,
    private readonly app: AppRepository
  ) {}

  getMyBusiness(id?: string): Observable<Business> {
    return this.api.get<Business>(`${API.BUSINESS}`).pipe(
      tap((data) => {
        this.store.store.update(upsertEntities(data));
        const business = data;
        this.app.update({
          business: business,
        });
      })
    );
  }

  updateBusiness(body: BusinessUpdateDTO, id?: string): Observable<any> {
    return this.api
      .patch<any>(API.BUSINESS, {
        body: body,
      })
      .pipe(
        tap((data) => {
          if (data) {
            this.app.update({
              business: data,
            });
            this.store.store.update(updateEntities(id, data));
          }
        })
      );
  }
  updateReferralProgram(
    body: ReferralProgramUpdateDTO,
    id?: string
  ): Observable<any> {
    return this.api
      .patch<any>(API.SET_REFERRAL_PROGRAM, {
        body: body,
      })
      .pipe(
        tap((data) => {
          if (data) {
            this.store.store.update(updateEntities(id, data));
          }
        })
      );
  }

  updatePassword(body?: any, id?: string): Observable<any> {
    return this.api
      .patch<any>(API.SET_PASSWORD, {
        body: body,
      })
      .pipe(
        tap((data) => {
          if (data) {
            this.store.store.update(updateEntities(id, data));
          }
        })
      );
  }
}
