/* eslint-disable @typescript-eslint/consistent-type-assertions */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable space-before-function-paren */
/* eslint-disable no-bitwise */
/* eslint-disable prefer-arrow/prefer-arrow-functions */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export enum DOC_ORIENTATION {
  Up = 1,
  Down = 3,
  Right = 6,
  Left = 8,
  UpMirrored = 2,
  DownMirrored = 4,
  LeftMirrored = 5,
  RightMirrored = 7,
  NotJpeg = -1,
  NotDefined = -2,
}

export type OutputExtension = 'PNG' | 'JPG';
export type OutputType = 'file' | 'base64' | 'blob';

export class ImageUtils {
  static fileMatchExts(fileName: string, acceptExtensions: string[]): boolean {
    const fileExtension = fileName.split('.').pop();
    return acceptExtensions.includes(fileExtension.toLocaleLowerCase());
  }
  //4032 * 4032
  //1024 * 1024
  static fileMatchSize(fileSize: number, maxSize: number): boolean {
    return fileSize <= 20971520; //20MB
  }

  /**
   * Lấy list image
   *
   * @param files Files
   * @param acceptExtensions Excepted Image Extensions
   * @param maxSize Max file size in MB
   * @returns Image file list
   */
  static filterImages(
    files: File[],
    acceptExtensions: string[] = ['png', 'jpg'],
    maxSize?: number
  ): File[] {
    if (files?.length === 0) {
      return [];
    }
    const filterFiles: File[] = [];
    for (const file of files) {
      if (this.fileMatchExts(file.name, acceptExtensions)) {
        const innerWidth = window.innerWidth;
        if(innerWidth < 767){
          if (maxSize == null || this.fileMatchSize(file.size, maxSize)) {
            filterFiles.push(file);
          }
        }else{
          filterFiles.push(file);
        }
      }
    }
    return filterFiles;
  }

  static fileResize(
    file: File,
    maxWidth: number,
    maxHeight: number,
    quality: number,
    outputExt: OutputExtension,
    outputFile: OutputType
  ): Promise<string | File | Blob | undefined> {
    return new Promise((resolve, reject) => {
      this.getOrientation(file, async (orientation) => {
        const sourceImage = document.createElement('img');

        sourceImage.src = await new Promise<any>((resolve) => {
          const reader = new FileReader();
          reader.onload = (e: any) => resolve(e.target.result);
          reader.readAsDataURL(file);
        });
        await new Promise((resolve) => (sourceImage.onload = resolve));

        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        ctx.drawImage(sourceImage, 0, 0);
        let height = sourceImage.height;
        let width = sourceImage.width;
        // Resize
        if (width > height) {
          if (width > maxWidth) {
            height = Math.round((height *= maxWidth / width));
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width = Math.round((width *= maxHeight / height));
            height = maxHeight;
          }
        }
        canvas.width = width;
        canvas.height = height;

        ctx.clearRect(0, 0, width, height);

        const TO_RADIANS = Math.PI / 180;
        if (orientation === DOC_ORIENTATION.Up) {
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        }
        // else if (orientation === DOC_ORIENTATION.Right) {
        //   ctx.save();
        //   ctx.rotate(90 * TO_RADIANS);
        //   ctx.translate(0, -canvas.width);
        //   ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
        //   ctx.restore();
        // } else if (orientation === DOC_ORIENTATION.Left) {
        //   ctx.save();
        //   ctx.rotate(-90 * TO_RADIANS);
        //   ctx.translate(-canvas.width, 0);
        //   ctx.drawImage(sourceImage, 0, 0, canvas.height, canvas.width);
        //   ctx.restore();
        // } else if (orientation === DOC_ORIENTATION.Down) {
        //   ctx.save();
        //   ctx.rotate(180 * TO_RADIANS);
        //   ctx.translate(-canvas.width, -canvas.height);
        //   ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        //   ctx.restore();
        // }
        else {
          // console.warn('ngx-image-compress - no orientation value found');
          // same as default UP
          ctx.drawImage(sourceImage, 0, 0, canvas.width, canvas.height);
        }

        const mimeType = `image/${outputExt.toLocaleLowerCase()}`;

        if (outputFile === 'base64') {
          resolve(canvas.toDataURL(mimeType, quality));
        } else {
          canvas.toBlob(
            (blob) => {
              if (outputFile === 'blob') {
                resolve(blob);
              } else {
                const fileOut: File = this.blobToFile(
                  blob,
                  file.name,
                  file.lastModified
                );
                resolve(fileOut);
              }
            },
            mimeType,
            quality
          );
        }
      });
      // reject();
    });
  }

  static blobToFile(blob: Blob, name: string, lastModified: number): File {
    // const file: any = blob;
    // file.name = name;
    // file.lastModified = lastModified;
    //Cast to a File() type
    return new File([blob], name, {
      lastModified: lastModified,
      type: blob.type,
    });
  }

  static base64toBlob(base64Data: string, mine: string) {
    // Split into two parts
    const parts = base64Data.split(';base64,');

    // Decode Base64 string
    const decodedData = window.atob(parts[1]);

    // Create UNIT8ARRAY of size same as row data length
    const uInt8Array = new Uint8Array(decodedData.length);

    // Insert all character code into uInt8Array
    for (let i = 0; i < decodedData.length; ++i) {
      uInt8Array[i] = decodedData.charCodeAt(i);
    }

    // Return BLOB image after conversion
    return new Blob([uInt8Array], { type: mine });
  }

  static getOrientation(
    file: File,
    callback: (result: DOC_ORIENTATION) => void
  ) {
    const reader = new FileReader();
    try {
      reader.onload = function ($event) {
        const view = new DataView(reader.result as ArrayBuffer);
        if (view.getUint16(0, false) !== 0xffd8) {
          return callback(-2);
        }
        const length = view.byteLength;
        let offset = 2;
        while (offset < length) {
          const marker = view.getUint16(offset, false);
          offset += 2;
          if (marker === 0xffe1) {
            if (view.getUint32((offset += 2), false) !== 0x45786966) {
              return callback(-1);
            }
            const little = view.getUint16((offset += 6), false) === 0x4949;
            offset += view.getUint32(offset + 4, little);
            const tags = view.getUint16(offset, little);
            offset += 2;
            for (let i = 0; i < tags; i++) {
              if (view.getUint16(offset + i * 12, little) === 0x0112) {
                return callback(view.getUint16(offset + i * 12 + 8, little));
              }
            }
          } else if ((marker & 0xff00) !== 0xff00) {
            break;
          } else {
            offset += view.getUint16(offset, false);
          }
        }
        return callback(-1);
      };
      reader.readAsArrayBuffer(file);
    } catch (e) {
      return callback(null);
    }
  }
}
