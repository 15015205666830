import { eachDayOfInterval } from 'date-fns';
/* eslint-disable @nrwl/nx/enforce-module-boundaries */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpSentEvent,
  HttpHeaderResponse,
  HttpProgressEvent,
  HttpResponse,
  HttpUserEvent,
} from '@angular/common/http';
import {
  BehaviorSubject,
  Observable,
  throwError,
  catchError,
  filter,
  switchMap,
  take,
} from 'rxjs';
import { AuthRepository, AuthService } from '../state/auth';
import { BusinessSARepository } from '@sticky/state/business-supper-admin';

@Injectable({ providedIn: 'root' })
export class HttpOverrideInterceptor implements HttpInterceptor {
  private isInProgressRefreshToken = false;
  private refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private repo: AuthRepository, private authService: AuthService, private busRepo: BusinessSARepository ) {}

  private setRequest(req: HttpRequest<any>, customToken?: string) {
    const headers: {
      [name: string]: string | string[];
    } = {};
    const headerKeys = req.headers?.keys();
    if (headerKeys.length > 0) {
      headerKeys.forEach((key) => {
        const headerValue = req.headers.get(key);
        if (headerValue != null) {
          headers[key] = headerValue;
        }
      });
    }
    if (req.params.has('Skip-Auth')) {
      const params = req.params.delete('Skip-Auth');
      return req.clone({ params });
    }
    // const token = customToken ?? this.repo.accessToken;
    const token = this.repo.accessToken ? this.repo.accessToken : this.repo.accessSupportToken;
    const supportedBID = this.busRepo.supportBus.busId
    // console.error(supportedBID)
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    if (token && supportedBID) {
      headers['supported-bid'] = supportedBID
    }
    return req.clone({ setHeaders: headers });
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<
    | HttpSentEvent
    | HttpHeaderResponse
    | HttpProgressEvent
    | HttpResponse<any>
    | HttpUserEvent<any>
    | any
  > {
    return next.handle(this.setRequest(req)).pipe(
      catchError((err) => {
        switch (err?.status) {
          case 503:
            // this._router.navigate([`/${appRoutes.MAINTENANCE}`]);
            break;
          case 403:

            // this.authService.logout();
            // location.reload();
            break;
          case 401:
            if (this.repo.refreshToken && this.repo.accessToken) {
              const supportedBID = this.busRepo.supportBus.busId
              if(supportedBID) {
                this.forceLogout();
              }
              return this.handle401Error(req, next);
            } else {
              // log.warn('Force Logout', this.isInProgressRefreshToken);
              this.forceLogout();
            }
            break;
        }
        return throwError(() => err);
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<any> {
    // // log.warn('handle401Error isInProgressRefreshToken', this.isInProgressRefreshToken);
    if (!this.isInProgressRefreshToken) {
      this.isInProgressRefreshToken = true;
      this.refreshToken$.next(null);
      return this.authService
        .refreshToken({ token: this.repo.refreshToken })
        .pipe(
          catchError((err) => {
            this.forceLogout();
            return throwError(() => err);
          }),
          switchMap((auth) => {
            // log.warn('RefreshToken OK. Re-connecting', request);
            this.refreshToken$.next(auth.accessToken);
            this.isInProgressRefreshToken = false;
            return next.handle(this.setRequest(request, auth.accessToken));
          })
        );
    }

    return this.refreshToken$.pipe(
      filter((token) => !!token),
      take(1),
      switchMap((token) => next.handle(this.setRequest(request, token)))
    );
  }

  private forceLogout() {
    this.isInProgressRefreshToken = false;
    // log.warn('Force Logout');
    this.repo.update({
      isSessionTimeout: true,
    });
  }
}
