import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError, catchError, tap } from 'rxjs';
import { ApiOptions, HttpMethod } from './common.type';
import { TranslocoService } from '@ngneat/transloco';
import { environment } from 'environments/environment';
interface LogData {
  path: string;
  timeResponse: number;
  method: HttpMethod;
  headers?: HttpHeaders;
  body?: any;
  params?: HttpParams;
  response?: any;
}
@Injectable({ providedIn: 'root' })
export class APIService {
  constructor(
    private http: HttpClient,
    private _translocoService: TranslocoService
  ) {}

  private setRequest<T>(
    path: string,
    data: {
      method?: HttpMethod;
      options?: ApiOptions;
    }
  ): Observable<T> {
    const method = data.method ?? 'GET';
    const apiPath = `${environment.apiPath}/${path}`;
    const headers =
      data.options?.headers ??
      new HttpHeaders({ 'Content-Type': 'application/json' });
    let params = new HttpParams();
    const detectDevice = this.getDetectionOs();
    const platform = 'stickyqr-bo';
    if (data.options?.params) {
      params = data?.options?.params;
      params = params.set('lang', this._translocoService.getActiveLang());
      params = params.set('os', `${detectDevice}`);
      params = params.set('platform', `${platform}`);
    } else {
      params = params.set('lang', this._translocoService.getActiveLang());
      params = params.set('os', `${detectDevice}`);
      params = params.set('platform', `${platform}`);
    }
    const bodyData =
      data.options?.responseType == 'json'
        ? JSON.stringify(data?.options?.body ?? {})
        : data?.options?.body;
    const responseType = data.options?.responseType ?? 'json';
    const isLogging = environment.enableLogger;
    let request: Observable<unknown>;
    switch (method) {
      case 'GET':
        switch (responseType) {
          case 'arraybuffer':
            request = this.http.get(`${apiPath}`, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          case 'text':
            request = this.http.get(`${apiPath}`, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          case 'blob':
            request = this.http.get(`${apiPath}`, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          default:
            request = this.http.get<T>(`${apiPath}`, {
              params: params,
              headers: headers,
              responseType: 'json',
            });
            break;
        }
        break;
      case 'POST':
        switch (responseType) {
          case 'arraybuffer':
            request = this.http.post(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          case 'text':
            request = this.http.post(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          case 'blob':
            request = this.http.post(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          default:
            request = this.http.post<T>(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: 'json',
            });
            break;
        }
        break;
      case 'PUT':
        switch (responseType) {
          case 'arraybuffer':
            request = this.http.put(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          case 'text':
            request = this.http.put(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          case 'blob':
            request = this.http.put(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          default:
            request = this.http.put<T>(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: 'json',
            });
            break;
        }
        break;
      case 'PATCH':
        switch (responseType) {
          case 'arraybuffer':
            request = this.http.patch(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          case 'text':
            request = this.http.patch(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          case 'blob':
            request = this.http.patch(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          default:
            request = this.http.patch<T>(`${apiPath}`, bodyData, {
              params: params,
              headers: headers,
              responseType: 'json',
            });
            break;
        }
        break;
      case 'DEL':
        switch (responseType) {
          case 'arraybuffer':
            request = this.http.delete(`${apiPath}`, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          case 'text':
            request = this.http.delete(`${apiPath}`, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          case 'blob':
            request = this.http.delete(`${apiPath}`, {
              params: params,
              headers: headers,
              responseType: responseType,
            });
            break;
          default:
            request = this.http.delete<T>(`${apiPath}`, {
              params: params,
              headers: headers,
              responseType: 'json',
              body: bodyData,
            });
            break;
        }
        break;
      default:
        return throwError(() => new Error(`${method} is not supported`));
    }
    const startFrom = new Date().getTime();
    return request.pipe(
      tap((response: any) => {
        if (isLogging) {
          this.logAPI({
            path: path,
            timeResponse: new Date().getTime() - startFrom,
            method: method,
            headers: headers,
            body: bodyData,
            params: params,
            response,
          });
          // const timePro = (new Date().getTime() - startFrom) / 1000;
          // this.logPath('POST REP', path, ` ${timePro}s`);
        }
      }),
      catchError(this.handleError.bind(this))
    );
  }

  get<T>(path: string, options?: ApiOptions): Observable<T> {
    return this.setRequest<T>(path, { method: 'GET', options: options });
  }

  post<T>(path: string, options?: ApiOptions): Observable<T> {
    return this.setRequest<T>(path, { method: 'POST', options: options });
  }

  put<T>(path: string, options?: ApiOptions): Observable<T> {
    return this.setRequest<T>(path, { method: 'PUT', options: options });
  }

  patch<T>(path: string, options?: ApiOptions): Observable<T> {
    return this.setRequest<T>(path, { method: 'PATCH', options: options });
  }

  /**
   *
   * @param path là đường dẫn tới api, còn địa chỉ Server API nó được thiết lập ban đầu ở thư mục enviroments
   */
  delete<T>(path: string, options?: ApiOptions): Observable<T> {
    return this.setRequest<T>(path, { method: 'DEL', options: options });
  }

  private handleError(error: any): Observable<any> {
    return throwError(() => error);
  }

  private logAPI(data: LogData) {
    const priorityStyle = this.colorByPriority(data.timeResponse);
    console.groupCollapsed(
      `%c[${(data.timeResponse / 1000).toFixed(1)}s -> ${priorityStyle.label}]`,
      `color: ${priorityStyle.hex}`,
      `[${data.method}]: ${data.path}`
    );
    if (data.headers) {
      const headersArray = data.headers
        .keys()
        .map((x) => ({ [x]: data.headers.get(x) }));
      console.info(`HEADERS: `, headersArray);
    }
    if (data.params) {
      console.warn(`PARAMS: `, data.params.toString());
    }
    if (data.body) {
      console.groupCollapsed(`BODY: `);
      console.table(data.body);
      console.groupEnd();
    }
    if (data.response) {
      console.groupCollapsed(`RESPONSE: `);
      console.table(data.response);
      console.groupEnd();
    }
    console.groupEnd();
  }

  private colorByPriority(data: number): {
    hex: string;
    label: string;
  } {
    if (data < 500) {
      return {
        hex: '#7BB661',
        label: 'PERFECT',
      };
    }

    if (data < 1400) {
      return {
        hex: '#0984FC',
        label: 'GOOD',
      };
    }

    if (data < 2500) {
      return {
        hex: '#00B9FC',
        label: 'NORMAL',
      };
    }

    if (data < 4000) {
      return {
        hex: '#FF5348',
        label: 'LOW',
      };
    }

    return {
      hex: '#E81839',
      label: 'SUPER LOW',
    };
  }

  private getDetectionOs() {
    if (typeof window !== 'undefined') {
      const userAgent = navigator.userAgent;
      // android detection
      if (/android/i.test(userAgent)) {
        return 'android';
      }
      // iOS detection
      if (/iPad|iPhone|iPod/.test(userAgent)) {
        return 'ios';
      }
      return 'web';
    }
  }
}
