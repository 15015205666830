import { CommonModule, NgClass, NgFor } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NavigationEnd, Router } from '@angular/router';
import { FuseDrawerComponent } from '@fuse/components/drawer';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { LanguagesSupport, appRoutes } from '@sticky/config';
import { Subject, Subscription, filter, takeUntil } from 'rxjs';
import { RouterModule } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { AppRepository } from '@sticky/state/app';
import { AuthService } from '@sticky/state/auth';
const loader = LanguagesSupport.reduce((acc: any, lang) => {
  acc[lang.id] = () => import(`./i18n/${lang.id}.json`);
  return acc;
}, {});
@Component({
  selector: 'navigation',
  templateUrl: './navigation.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  styles: [
    `
      navigation{
        height: 100%;
        display: flex;
        width: 100%;

      }
      .navigation a {
        border-bottom: 2px solid #ffffff;
      }
      .navigation a.active-vc,
      .navigation a.active {
        border-bottom: 2px solid #000000;
      }
      @media screen and (max-width: 1280px) {
          .navigation a.active-vc,
          .navigation .active {
            background-color: #f5f5f5;
            border-bottom: none !important;
          }
        }
    `,
  ],
  imports: [
    CommonModule,
    MatIconModule,
    FuseDrawerComponent,
    MatButtonModule,
    NgFor,
    NgClass,
    MatTooltipModule,
    RouterModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'navigation',
        loader,
      },
    },
  ],
})
export class NavigationComponent implements OnInit, OnDestroy {
  @Output() closeMenuChange = new EventEmitter();
  @Input() country: string
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  pathName: string;
  isScreenSmall: boolean;

  /**
   * Constructor
   */
  urlVoucher: string = '';
  private routerSubscription: Subscription;
  isActive: boolean = false;
  constructor(
    private _router: Router,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _appRepository: AppRepository,
    @Inject(AuthService) private _authService: AuthService,

  ) {
    this.pathName = this._router.url;
    this.urlVoucher = this._router.url?.split('?')[0];
    if(this.urlVoucher){
      this.updateActiveState();
    }
  }

  ngOnInit(): void {
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('lg');
      });
      this.routerSubscription = this._router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe((event: NavigationEnd) => {
        this.urlVoucher = event.urlAfterRedirects.split('?')[0];;
        this.updateActiveState();         
      });
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  updateActiveState(): void {
    const targetUrl = this.vouchersCalendar.split('?')[0];
    const parentRoute = this.urlVoucher.split('/')[1];
    if (this.urlVoucher === targetUrl || `/${parentRoute}` === targetUrl){
      this.isActive = true;
    }else{
      this.isActive = false;
    }
  }

  get vouchersCalendar(): string {
    return `/${appRoutes.VOUCHERS}`;
  }

  get role(){
    return this._appRepository?.role
  }

  get dashboard(): string {
    return `/${appRoutes.DASHBOARD.HOME}`;
  }

  get activities(): string {
    return `/${appRoutes.ACTIVITIES}`;
  }

  get rewards(): string {
    return `/${appRoutes.REWARDS}`;
  }
  get customers(): string {
    return `/${appRoutes.CUSTOMERS}`;
  }
  get usersStaff(): string {
    return `/${appRoutes.USER_STAFF}`;
  }
  get announcements(): string {
    return `/${appRoutes.ANNOUNCEMENTS}`;
  }
  get settings(): string {
    return `/${appRoutes.SETTINGS}`;
  }

  get business(): string {
    return `/${appRoutes.BUSINESS}`;
  }

  get campaigns(): string {
    return `/${appRoutes.CAMPAIGNS}`;
  }

  get offers(): string {
    return `/${appRoutes.OFFERS}`;
  }

  get vouchers(): string {
    return `/${appRoutes.VOUCHERS}/${appRoutes.CALENDAR}`;
  }

  onClouseMenu() {
    this.closeMenuChange.emit();
  }
  signOut(): void {
    this._authService.logout();
    this._router.navigate([`/${appRoutes.GET_STARTED}`]);
  }
}
