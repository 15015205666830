
<div class="list-business-dialog flex flex-col w-full h-full min-w-[250px] sm:min-w-[414px] md:min-w-[600px] bg-card">
  <div class="flex flex-row justify-between items-center w-full mb-4">
    <div class="sm:text-[24px] text-[16px] font-semibold">
      {{'busList.SEARCH_BUSINESS' | transloco}}
    </div>
    <button mat-icon-button (click)="dialogRef.close()" [tabIndex]="-1">
      <mat-icon class="icon-size-6" [svgIcon]="'close'"></mat-icon>
    </button>
  </div>
<div class="flex flex-col justify-between">
  <div class="flex flex-col items-start mt-4 sm:mt-0 md:mt-4">
    <!-- Search -->
    <div class="w-full input-search">
      <mat-form-field class="fuse-mat-dense fuse-mat-no-subscript w-full" subscriptSizing="dynamic">
        <mat-icon class="icon-size-5" matPrefix [svgIcon]="'heroicons_solid:magnifying-glass'"></mat-icon>
        <input matInput  [formControl]="searchInputControl" [autocomplete]="'off'"
          placeholder="{{'busList.SEARCH_PLACE' | transloco}}">
      </mat-form-field>
    </div>
  </div>
  <div class="list-business-content py-4 flex text-base flex-col pt-4 sticky bottom-0 bg-card w-full">
    <ng-container *ngIf="businesses?.length > 0">
      <ng-container *ngFor="let item of businesses">
        <div class="business-list-grid bg-card grid items-center gap-4 py-2 sm:px-4 px-2 border-b border-[#f5f5f5] cursor-pointer "
        (click)="onVisitingBusiness(item)"
          [ngClass]="{'hover:bg-gray-100': !selectedBus || selectedBu, 'bg-[#f5f5f5]': selectedBus && selectedBus === item?.id}">

          <div class="flex flex-0 items-center justify-center">
            <div *ngIf="item?.logo"
              class="flex min-w-[32px] min-h-[32px] w-[32px] h-[32px] rounded-full ">
            <ng-container *ngIf="item?.logo">
              <img class="object-cover w-full h-full rounded-full" [src]="item?.logo" alt="{{'busList.LOGO' | transloco}}" />
            </ng-container>
            </div>
            <div *ngIf="!item?.logo"
              class="flex flex-0 items-center justify-center min-w-[32px] min-h-[32px] w-[32px] h-[32px] rounded-full overflow-hidden">
              <div
                class="flex items-center justify-center w-full h-full rounded-full text-lg uppercase bg-gray-200 text-gray-600 dark:bg-gray-700 dark:text-gray-200">
                {{item?.name?.charAt(0).toUpperCase()}}
              </div>
            </div>
          </div>
          <div class="truncate text-[14px]">
            <div class="font-medium leading-5 truncate  text-[14px]">{{item?.name}}</div>
            <div class="leading-5 truncate text-[#999CA0] text-[12px]" *ngIf="item?.currentPlan">
              {{item?.currentPlan}}</div>
          </div>
          <div class="ml-2 text-[14px] absolute right-3">
            <a class="flex flex-row justify-end font-semibold flex-row text-[#3448F0]" *ngIf="busIdSelected === item?.id">
              <mat-icon class="icon-size-6" [svgIcon]="'icon-check-mark'" matTooltip="Select business"></mat-icon>
            </a>
          </div>
        </div>
        
      </ng-container>
    </ng-container>
  </div>
</div>
</div>