import { Pipe, PipeTransform } from '@angular/core';
import { AppRepository } from '@sticky/state/app';
import { isValid } from 'date-fns';
import { format, utcToZonedTime } from 'date-fns-tz';
import { vi as viLocale } from 'date-fns/locale';

@Pipe({
  name: 'formatDateTimezone'
})
export class FormatDateTimezonePipe implements PipeTransform {

  constructor(private appRepo: AppRepository) {}
  get business() {
    return this.appRepo?.business;
  }
  transform(key: string, locale: string): string {
    const dateTimezone = utcToZonedTime(key, this.business?.timeZone);
    if (locale === 'vi') {
      return format(new Date(dateTimezone), 'dd/MM/yyyy HH:mm', { locale: viLocale });
    } else {
      return format(new Date(dateTimezone), 'MM/dd/yyyy hh:mm a', { locale: undefined });
    }
  }
}