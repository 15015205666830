import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppLoadingComponent } from '@sticky/loading/loading.component';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppLoadingService {
  // private readonly _isLoading: BehaviorSubject<boolean> = new BehaviorSubject(
  //   false
  // );
  private dialogId = 'app-loading';
  constructor(public _matDialog: MatDialog) {}

  // get isLoading(): Observable<boolean> {
  //   return this._isLoading.asObservable();
  // }

  get dialogRef(): MatDialogRef<AppLoadingComponent> {
    return this._matDialog.getDialogById(this.dialogId);
  }

  hide(): void {
    // this._isLoading.next(false);
    // debugger
    if (this.dialogRef) {
      this.dialogRef.close();
    }
    this._matDialog.closeAll();
  }

  show(): void {
    // this._isLoading.next(true);
    this.hide();
    if (this.dialogRef) {
      return;
    }
    this._matDialog.open(AppLoadingComponent, {
      id: this.dialogId,
      panelClass: [
        'app-loading-panel',
        'p-6',
        'bg-white',
        'shadow-lg',
        'rounded-3xl',
        'bg-clip-padding',
        'bg-opacity-80',
        'text-black',
      ],
      backdropClass: ['app-loading-backdrop', 'bg-black', 'bg-opacity-30'],
      disableClose: true,
    });
  }
}
