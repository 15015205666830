<div class="navigation grid grid-cols-2 grid-flow-row w-full whitespace-nowrap	 "
  [ngClass]="{'grid grid-cols-2 grid-flow-row': isScreenSmall, 'flex' : !isScreenSmall }">
  <!-- Shortcut -->
  <div
    [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
    'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5' : !isScreenSmall }">
    <!-- Normal links -->
    <a (click)="onClouseMenu()" [routerLink]="dashboard" routerLinkActive='active'
      class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
      [ngClass]="{'flex-col py-8': isScreenSmall, 
        'py-0 px-8' : !isScreenSmall}">
      <div class="relative flex shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full lg:hidden"
        [ngClass]="{'mb-2 mt-0': isScreenSmall}">
        <mat-icon class="absolute z-20 icon-size-8" [svgIcon]="'icon_dashboard'"></mat-icon>
      </div>
      <div class="text-[16px] text-center">{{ 'navigation.DASHBOARD' | transloco }}</div>
    </a>
  </div>

  <div
    [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
    'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5' : !isScreenSmall }">
    <!-- Normal links -->
    <a (click)="onClouseMenu()" [routerLink]="activities" routerLinkActive='active'
      class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
      [ngClass]="{'flex-col py-8': isScreenSmall, 
        'py-0 px-8' : !isScreenSmall}">
      <div class="relative flex shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full lg:hidden"
        [ngClass]="{'mb-2 mt-0': isScreenSmall}">
        <mat-icon class="absolute z-20 icon-size-8" [svgIcon]="'icon_activities'"></mat-icon>
      </div>
      <div class="text-[16px] text-center">{{ 'navigation.ACTIVITIES' | transloco }}</div>
    </a>
  </div>

  <!-- <ng-container *ngIf="country === 'US'"> -->
    <div
      [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
      'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5' : !isScreenSmall }">
      <!-- Normal links -->
      <a (click)="onClouseMenu()" [routerLink]="campaigns" routerLinkActive='active'
        class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
        [ngClass]="{'flex-col py-8': isScreenSmall, 
          'py-0 px-8' : !isScreenSmall}">
        <div class="relative flex shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full lg:hidden"
          [ngClass]="{'mb-2 mt-0': isScreenSmall}">
          <mat-icon class="absolute z-20 icon-size-8" [svgIcon]="'campaigns'"></mat-icon>
        </div>
        <div class="text-[16px] text-center">{{ 'navigation.CAMPAIGNS' | transloco }}</div>
      </a>
    </div>
  <!-- </ng-container> -->

  <!-- <ng-container -->
    <div
      [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
      'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5' : !isScreenSmall }">
      <!-- Normal links -->
      <a (click)="onClouseMenu()" [routerLink]="vouchers" routerLinkActive='active'
        class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
        [ngClass]="{
          'flex-col py-8': isScreenSmall, 
          'py-0 px-8': !isScreenSmall, 
          'active-vc': isActive
        }">
        <div class="relative flex shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full lg:hidden"
          [ngClass]="{'mb-2 mt-0': isScreenSmall}">
          <mat-icon class="absolute z-20 icon-size-8" [svgIcon]="'icon-offers'"></mat-icon>
        </div>
        <div class="text-[16px] text-center">{{ 'navigation.VOUCHERS' | transloco }}</div>
      </a>
    </div>
  <!-- </ng-container> -->

  <div
    [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
    'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5' : !isScreenSmall }">
    <!-- Normal links -->
    <a (click)="onClouseMenu()" [routerLink]="rewards" routerLinkActive='active'
      class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
      [ngClass]="{'flex-col py-8': isScreenSmall, 
        'py-0 px-8' : !isScreenSmall}">
      <div class="relative flex shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full lg:hidden"
        [ngClass]="{'mb-2 mt-0': isScreenSmall}">
        <mat-icon class="absolute z-20 icon-size-8" [svgIcon]="'icon_rewards'"></mat-icon>
      </div>
      <div class="text-[16px] text-center">{{ 'navigation.REWARDS' | transloco }}</div>
    </a>
  </div>

  <div
    [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
    'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5' : !isScreenSmall }">
    <!-- Normal links -->
    <a (click)="onClouseMenu()" [routerLink]="customers" routerLinkActive='active'
      class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
      [ngClass]="{'flex-col py-8': isScreenSmall, 
        'py-0 px-8' : !isScreenSmall}">
      <div class="relative flex shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full lg:hidden"
        [ngClass]="{'mb-2 mt-0': isScreenSmall}">
        <mat-icon class="absolute z-20 icon-size-8" [svgIcon]="'icon_customers'"></mat-icon>
      </div>
      <div class="text-[16px] text-center">{{ 'navigation.CUSTOMERS' | transloco }}</div>
    </a>
  </div>

  <div
  [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
  'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5' : !isScreenSmall }">
  <!-- Normal links -->
  <a (click)="onClouseMenu()" [routerLink]="announcements" routerLinkActive='active'
    class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
    [ngClass]="{'flex-col py-8': isScreenSmall, 
      'py-0 px-8' : !isScreenSmall}">
    <div class="relative flex shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full  lg:hidden"
      [ngClass]="{'mb-2 mt-0': isScreenSmall}">
      <mat-icon class="absolute z-20 icon-size-8" [svgIcon]="'icon_announcements'"></mat-icon>
    </div>
    <div class="text-[16px] text-center">{{ 'navigation.ANNOUNCEMENTS' | transloco }}</div>
  </a>
</div>

  <ng-container *ngIf="role && role !== 'STAFF'">
    <div
      [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
    'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5' : !isScreenSmall }">
      <!-- Normal links -->
      <a (click)="onClouseMenu()" [routerLink]="usersStaff" routerLinkActive='active'
        class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
        [ngClass]="{'flex-col py-8': isScreenSmall, 
        'py-0 px-8' : !isScreenSmall}">
        <div class="relative flex shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full lg:hidden"
          [ngClass]="{'mb-2 mt-0': isScreenSmall}">
          <mat-icon class="absolute z-20 icon-size-8" [svgIcon]="'icon_staffs'"></mat-icon>
        </div>
        <div class="text-[16px] text-center">{{ 'navigation.USERS' | transloco }}</div>
      </a>
    </div>
  </ng-container>
  <!-- <div
    [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
    'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5' : !isScreenSmall }">
    <a (click)="onClouseMenu()" [routerLink]="dashboard" routerLinkActive='active'
      class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
      [ngClass]="{'flex-col py-6': isScreenSmall, 
        'py-0 px-8' : !isScreenSmall}">
      <div class="relative flex shrink-0 items-center justify-center w-12 h-12 rounded-full lg:hidden"
        [ngClass]="{'mb-3 m-auto': isScreenSmall}">
        <mat-icon class="absolute z-20 icon-size-5" [svgIcon]="'icon_campaign'"></mat-icon>
      </div>
      <div class="text-[14px] text-center">{{ 'navigation.CAMPAIGN' | transloco }}</div>
    </a>
  </div> -->
  
  
  <div
    [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
    'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5' : !isScreenSmall }">
    <!-- Normal links -->
    <a (click)="onClouseMenu()" [routerLink]="settings" routerLinkActive='active'
      class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
      [ngClass]="{'flex-col py-8': isScreenSmall, 
        'py-0 px-8' : !isScreenSmall}">
      <div class="relative flex shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full lg:hidden"
        [ngClass]="{'mb-2 mt-0': isScreenSmall}">
        <mat-icon class="absolute z-20 icon-size-8" [svgIcon]="'icon_settings'"></mat-icon>
      </div>
      <div class="text-[16px] text-center">{{ 'navigation.SETTINGS' | transloco }}</div>
    </a>
  </div>
  <div
    [ngClass]="{'relative group flex flex-col overflow-hidden bg-card border-r border-b even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5': isScreenSmall, 
    'relative group flex flex-col overflow-hidden bg-card even:border-r-0 hover:bg-gray-50 dark:hover:bg-black dark:hover:bg-opacity-5 hidden' : !isScreenSmall }">
    <!-- Normal links -->
    <a (click)="signOut()" routerLinkActive='active'
      class="flex items-center justify-center w-full h-full no-underline hover:bg-[#F5F5F5] cursor-pointer hover:text-[#FF4F0F]"
      [ngClass]="{'flex-col py-8': isScreenSmall, 
        'py-0 px-8' : !isScreenSmall}">
      <div class="relative flex shrink-0 items-center justify-center w-[32px] h-[32px] rounded-full lg:hidden"
        [ngClass]="{'mb-2 mt-0': isScreenSmall}">
        <mat-icon class="icon-size-8 text-black" [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
      </div>
      <div class="text-[16px] text-center">{{ 'navigation.SIGN_OUT' | transloco }}</div>
    </a>
  </div>

</div>