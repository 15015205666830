import {
  NgModule,
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnChanges,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ViewEncapsulation,
  ChangeDetectorRef,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslocoModule } from '@ngneat/transloco';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { PhoneMaskDirective } from './phone-mask.directive';
import {
  CountryTimeZone,
  countries,
  getDefaultCountry,
} from '@sticky/util-common';
import { NgxMaskDirective } from 'ngx-mask';
import { provideNgxMask } from 'ngx-mask';
import { NgxMaskPipe } from 'ngx-mask';
import { parse, format, AsYouType } from 'libphonenumber-js';

@Component({
  selector: 'stickyqr-phone-number',
  templateUrl: './phone-number.component.html',
  styleUrls: ['./phone-number.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PhoneNumberComponent implements OnInit, OnChanges {
  @Output() dialChange = new EventEmitter();
  @Output() numberChange = new EventEmitter();
  @Output() countryChange = new EventEmitter();
  @Output() flagImagePosChange = new EventEmitter();
  @Input() label: string = 'Phone';
  @Input() isLabel: boolean = true;
  @Input() isDisable: boolean = false;
  @Input() dial: any;
  @Input() flagImagePos: any;
  @Input() phoneNumber: any;
  @Input() isBusPhone: any;
  dialList = countries;
  countryTimeZone = CountryTimeZone;
  dialControl = new FormControl('');
  filteredDial: Observable<any[]>;
  // flagImagePos: any;
  dialCode: any;
  countryCode: any;
  formData: FormGroup = new FormGroup({
    phone: new FormControl(''),
  });
  selectedValue: any;
  searchTxt: any;
  constructor(private _cdr: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.filteredDial = this.dialControl.valueChanges.pipe(
      startWith(''),
      map((value) => {
        if (value === '') {
          return this.dialList.slice();
        }
        if (typeof value === 'string') {
          return this.filterDial(value);
        }
        return [];
      })
    );
    this.dialDefault();
    this.initialForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    // log.debug('changes', changes);
    if (changes) {
      let dialBus;
      if (changes['dial']) {
        dialBus = this.dialList.find((f) => f.code === this.dial);
        this.dialCode = dialBus?.code;
        this.countryCode = dialBus?.iso;
        // this.countryChange.emit(this.countryCode);
      }
      if (changes['phoneNumber']) {
        setTimeout(() => {
          this.f['phone'].setValue(this.phoneNumber || '');
        }, 1);
      }
    }
  }
  get f() {
    return this.formData.controls;
  }

  initialForm() {
    this.f['phone'].valueChanges.subscribe((c) => {
      this.dialChange.emit(this.dialCode);
      this.countryChange.emit(this.countryCode);
      this.numberChange.emit(c);
    });
  }
  get mask() {
    switch (this.countryCode) {
      case 'us':
        return '(000) 000-0000 000';
        break;
      case 'vn':
        return '000 00000000';
        break;
      case 'af':
        return '000 000 0000';
        break;
      case 'al':
        return '00 000 00000';
        break;
      case 'dz':
        return '0 000 00000';
        break;
      default:
        return '000 000 0000000';
    }
  }

  dialSelectChange(event: any) {
    this._cdr.markForCheck();
    this.flagImagePos = event?.value?.flagImagePos;
    this.dialCode = event?.value?.code;
    this.countryCode = event?.value?.iso;
    this.dialChange.emit(this.dialCode);
    this.countryChange.emit(event?.value?.iso);
    this.flagImagePosChange.emit(this.flagImagePos);
  }

  filterDial(iso: string): any[] {
    const filterValue = iso.toLowerCase();
    return this.dialList.filter(
      (option) => option.iso.toLowerCase().indexOf(filterValue) === 0
    );
  }

  displayDial(c: any): boolean {
    return c;
  }

  dialDefault() {
    if (!this.dialCode) {
      const defaultCountry = getDefaultCountry();
      const filterDialCode = this.dialList.find(
        (f) => f.iso.toLowerCase() === defaultCountry?.toLowerCase()
      );
      this.dialCode = filterDialCode?.code;
      this.flagImagePos = filterDialCode?.flagImagePos;
    }
    if (!this.flagImagePos) {
      this.flagImagePos = this.dialList[0].flagImagePos;
    }
  }
}

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    TranslocoModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  declarations: [PhoneNumberComponent, PhoneMaskDirective],
  exports: [PhoneNumberComponent, PhoneMaskDirective],
  providers: [provideNgxMask()],
})
export class PhoneNumberComponentModule {}
