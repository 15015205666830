
<fuse-loading-bar></fuse-loading-bar>
<div class="flex flex-col flex-auto w-full min-w-0">
  <div
    class="relative flex flex-col flex-0 justify-center w-full h-16 sm:h-20 lg:h-36 overflow-hidden z-0 dark:shadow-none print:hidden" *ngIf="redirectURL != '/business'">
    <div class="relative dark flex flex-auto justify-center w-full px-4 md:px-8 bg-[#3a3a3a]">
        <div class="flex items-center w-full h-16 sm:h-20">
          <div class="flex items-center justify-center">
            <ng-container *ngIf="business?.logo">
              <div class="bg-contain min-w-[32px] w-[32px] h-[32px] bg-no-repeat bg-center	"
                [style.background-image]='"url(\"" + business?.logo + "\")"'></div>
            </ng-container>
            <div class="flex flex-row sm:max-w-full line-clamp-2 flex">
              <div class="font-medium pl-2 text-[14px] sm:text-[16px]">{{business?.name}}</div>
              <button *ngIf="supportRole =='CUSTOMER_SERVICE'" class="flex flex-row items-center w-[20px] max-w-[250px] sm:max-w-full" (click)="onOpenListBusinessDialog()">
                <mat-icon class="ml-1 icon-size-5" [svgIcon]="'arrow-down'"></mat-icon>
              </button>
            </div>
          </div>
          <div class="flex items-center pl-2 ml-auto space-x-0.5 sm:space-x-2">
            <languages></languages>
            <ng-container *ngIf="isScreenSmall">
              <shortcuts [country]="business?.address?.country"></shortcuts>
            </ng-container>
              <user class="hidden lg:block" [showAvatar]="false"></user>
          </div>
        </div>
    </div>

    <ng-container *ngIf="!isScreenSmall">
      <div class="flex flex-auto justify-center px-4 md:px-8 bg-card dark:bg-gray-700 border-b-2">
        <div class="relative flex items-center w-full h-16">
          <shortcuts [country]="business?.address?.country" class="w-full h-full flex items-center"></shortcuts>
        </div>
      </div>
      
    </ng-container>

  </div>
  <!-- <div *ngIf="listApprove?.length > 0 && getApproveUrl" class="bg-[#FCF7E5] flex flex-col p-4 w-full">
    <approve [listApprove]="listApprove"></approve>
  </div> -->
  <div class="flex flex-col flex-auto">
    
    <router-outlet *ngIf="true"></router-outlet>
    
  </div>
</div>