/* eslint-disable curly */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import parseISO from 'date-fns/parseISO';
import fromUnixTime from 'date-fns/fromUnixTime';
import isDate from 'date-fns/isDate';
import { format, formatInTimeZone, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { parse } from 'date-fns';
export class DateTimeHelper {
  static isDiffTimezone(businessTimezone: string, isDebug = false) {
    const deviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const now = new Date();
    const deviceTime = formatInTimeZone(now, deviceTimezone, 'HH:mm');
    const businessTime = formatInTimeZone(now, businessTimezone, 'HH:mm');
    const isDiff = deviceTime !== businessTime;
    // if (isDebug) {
    //   console.log(`Device Timezone: ${deviceTimezone} - Time: ${deviceTime}\n`);
    //   console.log(`Business Timezone: ${businessTimezone} - Time: ${businessTime}\n`);
    //   console.log(`Is Diff: ${isDiff}`);
    // }
    return isDiff;
  }

  static getUTCUnix(date): any {
    const cur = new Date(date);
    const utcT = Date.UTC(
      cur.getFullYear(),
      cur.getMonth(),
      cur.getDate(),
      cur.getHours(),
      cur.getMinutes(),
      cur.getSeconds()
    );
    return utcT / 1000;
  }

  static convertUnixToUTCDate(unix) {
    const dtLc = fromUnixTime(unix);
    return new Date(
      dtLc.getUTCFullYear(),
      dtLc.getUTCMonth(),
      dtLc.getUTCDate(),
      dtLc.getUTCHours(),
      dtLc.getUTCMinutes(),
      dtLc.getUTCSeconds()
    );
  }

  static getDate(unix): Date {
    return fromUnixTime(unix);
  }

  static dateTimeParseISO(value: string) {
    const date = parseISO(value);
    if (isDate(date)) return date;
    return;
  }

  static convertToUtc(date: Date, businessTimezone: string) {
    if (this.isDiffTimezone) {
      return zonedTimeToUtc(date, businessTimezone);
    }
    return zonedTimeToUtc(
      date,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  }

  static convertUtcToLocal(date: Date, businessTimezone: string) {
    if (this.isDiffTimezone) {
      return utcToZonedTime(date, businessTimezone);
    }
    return utcToZonedTime(
      date,
      Intl.DateTimeFormat().resolvedOptions().timeZone
    );
  }

  static convertToUserTimezone(dateString: string, timeString: string, timeZone: string) {
    const dateF = format(new Date(dateString), 'yyyy-MM-dd')
    const time = timeString || '23:59';
    const dateTimeString = `${dateF} ${time}`;
    const date = parse(dateTimeString, 'yyyy-MM-dd HH:mm', new Date());
    const zonedDate = utcToZonedTime(date, timeZone);
    const utcDate = zonedDate.toISOString();
    return utcDate;
  }

  static parseDateTime(dateString: string, timeString: string){
    const dateF = format(new Date(dateString), 'yyyy-MM-dd')
    const dateTimeString = `${dateF} ${timeString}`;
    const date = parse(dateTimeString, 'yyyy-MM-dd HH:mm', new Date());
    return date
  }
}
