import { BooleanInput } from '@angular/cdk/coercion';
import { NgClass, NgIf } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatDialog } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute, Router } from '@angular/router';
import { TRANSLOCO_SCOPE, TranslocoModule } from '@ngneat/transloco';
import { LanguagesSupport, appRoutes } from '@sticky/config';
import { Logger } from '@sticky/services';
import { AppRepository } from '@sticky/state/app';
import { AuthRepository, AuthService } from '@sticky/state/auth';
import { BusinessService } from '@sticky/state/business';
// import { AuthService } from '@sticky/auth/auth.service';
import { UserService } from '@sticky/user/user.service';
import { User } from '@sticky/user/user.types';
import { DateTimeHelper } from '@sticky/util-common';
import { TimeZoneDialogComponent } from 'app/modules/admin/time-zone-dialog/time-zone-dialog.component';
import { Subject, takeUntil } from 'rxjs';
const log = new Logger('UserComponent');

const loader = LanguagesSupport.reduce((acc: any, lang) => {
  acc[lang.id] = () => import(`./i18n/${lang.id}.json`);
  return acc;
}, {});
@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  exportAs: 'user',
  standalone: true,
  imports: [
    MatButtonModule,
    MatMenuModule,
    NgIf,
    MatIconModule,
    NgClass,
    MatDividerModule,
    TranslocoModule,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'userNav',
        loader,
      },
    },
  ],
  styles:[`
      .user-menu {
        .mat-divider{
          border-top-width: 0px;
          border-top-style: solid;
        }
      }
  `]
})
export class UserComponent implements OnInit, OnDestroy {
  /* eslint-disable @typescript-eslint/naming-convention */
  static ngAcceptInputType_showAvatar: BooleanInput;
  /* eslint-enable @typescript-eslint/naming-convention */

  @Input() showAvatar: boolean = true;
  user: User;

  private _unsubscribeAll: Subject<any> = new Subject<any>();
  /**
   * Constructor
   */
  userProfile;
  redirectURL
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private _router: Router,
    private _userService: UserService,
    @Inject(AuthService) private _authService: AuthService,
    public repo: AuthRepository,
    private busService: BusinessService,
    private authService: AuthService,
    private _activatedRoute: ActivatedRoute,
    private appRepo: AppRepository,
    public dialog: MatDialog,
  ) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to user changes
    this._userService.user$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((user: User) => {
        this.user = user;

        // Mark for check
        this._changeDetectorRef.markForCheck();
      });
    this.authService.getUserProfile().subscribe((data) => {
      this.userProfile = data;
    });
    if(this.role === 'OWNER') {
      this._activatedRoute.data.subscribe(({ res }) => {
        this.redirectURL = this._activatedRoute.snapshot['_routerState'].url
      });
    }
    this.busService.getMyBusiness().subscribe((data) => {
      this._changeDetectorRef.detectChanges();
      if (data.role == 'OWNER' && this.redirectURL == '/business' ) {
        this._router.navigate([`/${appRoutes.DASHBOARD.HOME}`], {
          relativeTo: this._activatedRoute,
        });
      }
      const tz = data?.timeZone
      this.checkDiffTimezone(tz)
    });
  }

  checkDiffTimezone(tz) {
    if (this.isShownDiffTimezone) {
      return;
    }
    const isDiffTimezone = DateTimeHelper.isDiffTimezone(tz, true);
      if (isDiffTimezone) {
        setTimeout(() => {
          const openTabDialogRef = this.dialog.open(TimeZoneDialogComponent, {
            panelClass: 'time-zone-dialog',
          });
          openTabDialogRef.afterClosed().subscribe(() => {});
          localStorage.setItem('IS_SHOW_DIFF_TIMEZONE', 'true');
        }, 2000);
      }
  }

  get isShownDiffTimezone(): boolean {
    const localSet = localStorage.getItem('IS_SHOW_DIFF_TIMEZONE');
    return localSet === 'true';
  }

  get accountName() {
    return this.appRepo.accountName;
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Update the user status
   *
   * @param status
   */
  get role() {
    return this.repo?.role;
  }
  get supportRole() {
    return this.repo?.supportRole;
  }

  updateUserStatus(status: string): void {
    // Return if user is not available
    if (!this.user) {
      return;
    }

    // Update the user
    this._userService
      .update({
        ...this.user,
        status,
      })
      .subscribe();
  }

  /**
   * Sign out
   */
  signOut(): void {
    this._authService.logout();
    this._router.navigate([`/${appRoutes.GET_STARTED}`]);
  }

  signOutSA(): void {
    this.supportRole == 'CUSTOMER_SERVICE' && !this.role ? this._router.navigate([`/${appRoutes.BUSINESS_SUPPORT}`]) : this._router.navigate([`/${appRoutes.GET_STARTED}`]);
    this._authService.logout();
  }
}
