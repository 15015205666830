import { Currency } from '@sticky/util-common';
import { Injectable } from '@angular/core';
import { createStore, withProps, select } from '@ngneat/elf';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { Business } from './business.model';
import {
  selectAllEntitiesApply,
  selectEntities,
  withEntities,
} from '@ngneat/elf-entities';

const store = createStore({ name: 'business' }, withEntities<Business>());

const persist = persistState(store, {
  key: 'business',
  storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class BusinessRepository {
  constructor() {}

  update(business: Business) {
    store.update((state) => ({ ...state, ...business }));
  }

  get store() {
    return store;
  }

  get business() {
    return this.store.getValue();
  }
}
