import { Pipe, PipeTransform } from '@angular/core';
import { parsePhoneNumber } from 'libphonenumber-js';
@Pipe({
  name: 'phoneFormat',
})
// eslint-disable-next-line @typescript-eslint/naming-convention
export class PhoneNumberPipe implements PipeTransform {
  transform(value: string): any {
    const phoneNumber = parsePhoneNumber(value)
    const phoneFormat = phoneNumber.formatInternational()
    return phoneFormat;
  }
}
