import { Inject, Injectable } from '@angular/core';
import { Logger } from './logger.service';
import {
  HotToastService,
  CreateHotToastRef,
  ToastOptions,
  Toast,
} from '@ngneat/hot-toast';
import { Content as ToastContent } from '@ngneat/overview';
import { Router } from '@angular/router';
import { AppLoadingService } from './loading.service';
import {
  FuseConfirmationService,
  FuseConfirmationConfig as ConfirmOptions,
} from '@fuse/services/confirmation';
import { AppDialog, DialogConfirmOpts } from '@sticky/types';
const log = new Logger('AppBased');
@Injectable({
  providedIn: 'root',
})
export class AppBased implements AppDialog {
  constructor(
    @Inject(HotToastService) private _toast: HotToastService,
    private _loading: AppLoadingService,
    private _fuseConfirmationService: FuseConfirmationService,
    private router: Router
  ) {
    log.info('AppBased Init');
  }

  /// ROUTING

  go(path: string, extras?: any) {
    this.router.navigate([path], extras);
  }

  /// LOADING

  /**
   * Show/Hide loading
   *
   * @param isShow True/False
   * @returns void
   */
  loading(isShow = true): void {
    this._loading.hide();
    if (isShow) {
      this._loading.show();
      return;
    }
  }

  /// DIALOG

  /**
   * Advanced confirm dialog
   *
   * @param options Dialog Options
   * @returns Ref
   */
  dialog(options?: ConfirmOptions) {
    return this._fuseConfirmationService.open(options);
  }

  okDialog(title: string, options?: DialogConfirmOpts) {
    return this.dialog({
      title: title,
      message: options?.message ?? '',
      icon: {
        show: options?.iconName != null,
        name: options?.iconName,
        color: options?.iconColor ?? 'primary',
      },
      actions: {
        confirm: {
          show: true,
          label: options?.okTitle ?? 'OK',
          color: options?.okColor ?? 'primary',
        },
        cancel: {
          show: false,
        },
      },
    });
  }

  yesnoDialog(title: string, options?: DialogConfirmOpts) {
    return this.dialog({
      title: title,
      message: options?.message ?? '',
      icon: {
        show: true,
        name: options?.iconName ?? 'mat_outline:help',
        color: options?.iconColor ?? 'primary',
      },
      actions: {
        confirm: {
          show: true,
          label: options?.okTitle ?? 'Yes',
          color: options?.okColor ?? 'primary',
        },
        cancel: {
          show: true,
          label: options?.cancelTitle ?? 'No',
        },
      },
    });
  }

  /**
   * Override toast options
   */
  private _toastOptions(options?: ToastOptions<Toast<any>>): ToastOptions<any> {
    const toastOptions: ToastOptions<any> = {
      ...options,
    };
    return toastOptions;
  }

  get customToast(): HotToastService {
    return this._toast;
  }

  toast(
    message?: ToastContent,
    options?: ToastOptions<Toast<any>>
  ): CreateHotToastRef<any> {
    return this._toast.show(message, this._toastOptions(options));
  }

  /**
   * Toast Observe
   *
   * @param loadingMessage Text or Ref
   * @param successTemplate  Text or Ref
   * @param errorTemplate  Text or Ref
   * @returns Observable
   */
  toastObserve(
    loadingMessage?: string,
    successTemplate?: any,
    errorTemplate?: any
  ) {
    return this._toast.observe({
      loading: loadingMessage,
      success: successTemplate,
      error: errorTemplate,
    });
  }

  toastSuccess(
    message?: ToastContent,
    options?: ToastOptions<Toast<any>>
  ): CreateHotToastRef<any> {
    return this._toast.success(
      message,
      this._toastOptions({
        duration: 2000,
      })
    );
  }

  toastError(
    message?: ToastContent,
    options?: ToastOptions<Toast<any>>
  ): CreateHotToastRef<any> {
    return this._toast.error(message, this._toastOptions());
  }

  toastInfo(
    message?: ToastContent,
    options?: ToastOptions<Toast<any>>
  ): CreateHotToastRef<any> {
    return this._toast.info(message, this._toastOptions(options));
  }

  toastWarning(
    message?: ToastContent,
    options?: ToastOptions<Toast<any>>
  ): CreateHotToastRef<any> {
    return this._toast.warning(message, this._toastOptions(options));
  }
}
