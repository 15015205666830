import { AfterContentInit, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { AuthRepository } from '../@sticky/state/auth';
import { Subscription } from 'rxjs';
import { appRoutes } from '@sticky/config';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AuditService } from '@sticky/state/audit';
export let browserRefresh = false;
import * as Sentry from '@sentry/angular-ivy';
import { AppRepository } from '@sticky/state/app';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, NgxMaskDirective, NgxMaskPipe],
  providers: [provideNgxMask()],
})
export class AppComponent implements AfterContentInit {
  /**
   * Constructor
   */
  subscription: Subscription;
  constructor(
    private auditService: AuditService,
    private authRepo: AuthRepository,
    private appRepo: AppRepository,
    public dialog: MatDialog
  ) {
    // if (this.supportRole === 'CUSTOMER_SERVICE' && this.role !== 'OWNER') {
    //   this.changeDetectorRef.markForCheck();
    //   this.router.navigate([`/${appRoutes.DASHBOARD}`], {
    //     relativeTo: this.activatedRoute,
    //   });
    // } else {
    //   this.changeDetectorRef.markForCheck();
    //   this.router.navigate([`/${appRoutes.BUSINESS}`], {
    //     relativeTo: this.activatedRoute,
    //   });
    // }

    this.checkAllowAudit();
    const scope = Sentry.getCurrentScope();
    scope.setTag('userId', this.authRepo.userId);
    scope.setTag('businessId', this.appRepo.currentBid);
    // this.checkDiffTimezone();
  }

  ngAfterContentInit(): void {
  }

  checkAllowAudit() {
    this.auditService.checkAudit().subscribe(() => {});
  }

  get role() {
    return this.authRepo?.role;
  }
  get supportRole() {
    return this.authRepo?.supportRole;
  }

  get dashboard(): string {
    return `/${appRoutes.DASHBOARD_SA}`;
  }

  get business(): string {
    return `/${appRoutes.BUSINESS}`;
  }
}
