import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyHelper } from '@sticky/config/currencies.helper';
import { AppRepository } from '@sticky/state/app';
import { AuthRepository } from '@sticky/state/auth';
@Pipe({
  name: 'stickyCurrency',
})
export class StickyQRCurrencyPipe implements PipeTransform {
  constructor(private cp: DecimalPipe, private app: AppRepository) {}
  transform(
    value: number,
    override?: boolean,
    code?: string,
    display?: string,
    digit?: string,
    prefix?: boolean,
    isBracket?: boolean
  ): any {
    const currency = this.app.currencySym;
    if (override === true) {
      // Custom Override
      const currencyInfo =
        code === ''
          ? CurrencyHelper.getCurrency(currency)
          : CurrencyHelper.getCurrency(code);
      const cDigit = digit || currencyInfo.decimalDigits;
      const cPrefix = prefix || currencyInfo.prefix;
      const cDisplay =
        display && (display === 'name' || display === 'symbol')
          ? display === 'name'
            ? currencyInfo.name
            : currencyInfo.symbol
          : currencyInfo.symbol;
      const pup = this.cp.transform(value, cDigit) || '0';
      if (parseInt(pup) < 0) {
        let pup2 = pup.replace('-', '');
        const result = cPrefix ? `-${cDisplay} ${pup2}` : `${pup} ${cDisplay}`;

        return result;
      }
      const result = cPrefix ? `${cDisplay} ${pup}` : `${pup} ${cDisplay}`;
      return result;
    } else {
      const currencyInfo = CurrencyHelper.getCurrency(currency);
      const pup = this.cp.transform(value, currencyInfo.decimalDigits) || '0';

      if (parseInt(pup) < 0) {
        let pup2 = pup.replace('-', '');
        const result = currencyInfo.prefix ? `-${currencyInfo.symbol}${pup2}` : `${pup}${currencyInfo.symbol}`;
        return result;
      }
      const result = currencyInfo.prefix ? `${currencyInfo.symbol}${pup}` : `${pup}${currencyInfo.symbol}`;
      return result;
    }
  }
}
