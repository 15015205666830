<!-- Button -->
<button mat-icon-button [matMenuTriggerFor]="userActions">
  <span class="relative">
    <img class="w-7 h-7 rounded-full" *ngIf="showAvatar && user?.avatar" [src]="user?.avatar">
    <mat-icon *ngIf="!showAvatar || !user?.avatar" [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span class="absolute right-0 bottom-0 w-2 h-2 rounded-full" [ngClass]="{'mr-px mb-px': !showAvatar || !user?.avatar,
                          'bg-green-500': user?.status === 'online',
                          'bg-amber-500': user?.status === 'away',
                          'bg-red-500': user?.status === 'busy',
                          'bg-gray-400': user?.status === 'not-visible'}"></span>
  </span>
</button>

<mat-menu class="p-0" [xPosition]="'before'" #userActions="matMenu" class="user-menu">
  <div (click)="$event.stopPropagation()" class="cursor-default h-[48px] border-b flex flex-row items-center px-4" *ngIf="userProfile?.displayName">
    <span class="flex flex-col leading-none">
      <span class="textruncate">{{userProfile?.displayName}}</span>
    </span>
  </div>
  <ng-container *ngIf="!userProfile?.displayName && accountName">
    <div (click)="$event.stopPropagation()" class="cursor-default border-b h-[48px] flex flex-row items-center px-4" >
      <span class="flex flex-col leading-none">
        <span class="textruncate">{{accountName}}</span>
      </span>
    </div>
  </ng-container>
  <!-- <button mat-menu-item *ngIf="!userProfile?.displayName">
    <span class="flex flex-col leading-none">
      <span>{{accountName}}</span>
    </span>
  </button> -->
  <mat-divider class="my-0"></mat-divider>
  <!-- <button mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
    <span>Profile</span>
  </button> -->
  <!-- <button mat-menu-item>
    <mat-icon [svgIcon]="'heroicons_outline:cog-8-tooth'"></mat-icon>
    <span>Settings</span>
  </button>
  <button mat-menu-item [matMenuTriggerFor]="userStatus">
    <mat-icon [svgIcon]="'heroicons_outline:ellipsis-horizontal-circle'"></mat-icon>
    <span>Status</span>
  </button> -->
  <mat-divider class="my-0"></mat-divider>
  <ng-container *ngIf="supportRole === 'CUSTOMER_SERVICE'; else saTemp">
    <button mat-menu-item (click)="signOutSA()">
      <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
      <span>{{ 'userNav.SIGN_OUT' | transloco }}</span>
    </button>
  </ng-container>
  <ng-template #saTemp>
    <button mat-menu-item (click)="signOut()">
      <mat-icon [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"></mat-icon>
      <span>{{ 'userNav.SIGN_OUT' | transloco }}</span>
    </button>
  </ng-template>

</mat-menu>