import { el } from 'date-fns/locale';
import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  CanLoad,
  Params,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';
import { AuthRepository } from './auth.repository';
import { AppRoutes } from '@sticky/config';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild, CanLoad {
  /**
   * Constructor
   */
  constructor(private _authRepo: AuthRepository, private _router: Router, private _activeRoute: ActivatedRoute) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Can activate
   *
   * @param route
   * @param state
   */
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl);
  }

  /**
   * Can activate child
   *
   * @param childRoute
   * @param state
   */
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const redirectUrl = state.url === '/sign-out' ? '/' : state.url;
    return this._check(redirectUrl);
  }

  /**
   * Can load
   *
   * @param route
   * @param segments
   */
  canLoad(
    route: Route,
    segments: UrlSegment[]
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this._check('/');
  }

  private _check(redirectURL: string): Observable<boolean> {
    if(!this._authRepo.isSupported) {
      return this._authRepo.isLoggedIn$.pipe(
        switchMap((authenticated) => {
          if (!authenticated) {
            this._router.navigate([`${AppRoutes.getStarted}`], {queryParams: { redirectURL }});
            return of(false);
          }
          return of(true);
        })
      );
    } else {
      return this._authRepo.isSupportLoggedIn$.pipe(
        switchMap((authenticated) => {
          if (!authenticated) {
            this._router.navigate([`${AppRoutes.business}`]);
            return of(false);
          }
          return of(true);
        })
      );
    }
  }
}
