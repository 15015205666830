import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { EnvironmentProviders, Provider } from '@angular/core';
import { devTools } from '@ngneat/elf-devtools';
import { HttpOverrideInterceptor } from './http.interceptor';

devTools();
export const provideAppCommon = (): Array<Provider | EnvironmentProviders> => {
  return [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpOverrideInterceptor,
      multi: true,
    },
  ];
};
