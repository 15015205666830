/* eslint-disable @typescript-eslint/member-ordering */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/naming-convention */
export enum LogLevel {
  Off = 0,
  Table,
  Error,
  Warning,
  Info,
  Debug,
}
/**
 * Log output handler function.
 */
export type LogOutput = (
  source: string,
  level: LogLevel,
  ...objects: any[]
) => void;

export class Logger {
  /**
   * Current logging level.
   * Set it to LogLevel.Off to disable logs completely.
   */
  static level = LogLevel.Debug;

  /**
   * Additional log outputs.
   */
  static outputs: LogOutput[] = [];

  /**
   * Enables production mode.
   * Sets logging level to LogLevel.Warning.
   */
  static enableProductionMode(isDebug = false): void {
    Logger.level = isDebug ? LogLevel.Debug : LogLevel.Off;
    if (!isDebug) {
      if (window) {
        // tslint:disable: only-arrow-functions
        window.console.log = () => {};
        window.console.warn = () => {};
        window.console.info = () => {};
        window.console.error = () => {};
        window.console.table = () => {};
      }
    }
  }

  constructor(private source?: string) {}

  /**
   * Logs messages or objects  with the debug level.
   * Works the same as console.log().
   */
  debug(...objects: any[]): void {
    this.log(console.log, LogLevel.Debug, objects);
  }

  /**
   * Logs messages or objects  with the info level.
   * Works the same as console.log().
   */
  info(...objects: any[]): void {
    // tslint:disable-next-line: no-console
    this.log(console.info, LogLevel.Info, objects);
  }

  /**
   * Logs messages or objects  with the warning level.
   * Works the same as console.log().
   */
  warn(...objects: any[]): void {
    this.log(console.warn, LogLevel.Warning, objects);
  }

  /**
   * Logs messages or objects  with the error level.
   * Works the same as console.log().
   */
  error(...objects: any[]): void {
    this.log(console.error, LogLevel.Error, objects);
  }

  /**
   * Logs messages or objects  with the table level.
   * Works the same as console.log().
   */
  table(...objects: any[]): void {
    this.log(console.table, LogLevel.Table, objects);
  }

  private log(func: Function, level: LogLevel, objects: any[]): void {
    if (level <= Logger.level) {
      const d = new Date();

      const log = this.source
        ? [`[${d.getMinutes()}:${d.getSeconds()}] [${this.source}]`].concat(
            objects
          )
        : [`[${d.getMinutes()}:${d.getSeconds()}]`].concat(objects); //objects
      func.apply(console, log);
      // Logger.outputs.forEach((output) =>
      //   output.apply(output, [this.source, level].concat(objects))
      // );
    }
  }
}
