/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { AuthRepository } from './auth.repository';
import { getRegistry } from '@ngneat/elf';
import {
  ForgotPassDTO,
  ForgotPassIsValidDTO,
  ForgotPassResendDTO,
  ForgotPassVerifyDTO,
  LoginDTO,
  LoginResponseDTO,
  PhoneCheckDTO,
  RefreshTokenDTO,
  SetPassDTO,
  SetPassIsValidDTO,
  SetPassResendDTO,
  SetPassVerifyDTO,
  businessSignUpDTO,
  businessSignUpResendDTO,
  businessSignUpVerifyDTO,
} from './auth.dto';
import { Observable, switchMap, tap } from 'rxjs';
import { API, APIService } from '@sticky/common';
import { AppRepository } from '../app';
import { AuthBusinessSignUp, AuthLogin, AuthPhoneCheck } from './auth.model';
import { AppBased } from '@sticky/services';
import { upsertEntities } from '@ngneat/elf-entities';

/**
 * Đây là based service dành cho xử lý chung
 */
@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private readonly api: APIService,
    private readonly store: AuthRepository,
    private readonly appRepo: AppRepository,
    private app: AppBased
  ) {}
  login(body: LoginDTO): Observable<AuthLogin> {
    return this.api
      .post<AuthLogin>(API.AUTH_LOGIN, {
        body: body,
      })
      .pipe(
        tap((data) => {
          if (data.role != 'OWNER') {
            return;
          }
          localStorage.removeItem('IS_SHOW_DIFF_TIMEZONE');
          if (data?.accessToken) {
            // Save vào store
            this.store.update({
              accessToken: data?.accessToken,
              refreshToken: data?.refreshToken,
              userId: data?.userId,
              isSessionTimeout: false,
              role: data?.role,
              supportRole: data?.supportRole,
            });
          }
          const business = data?.business;
          this.appRepo.update({
            business: business,
            currentBusinessId: business?.id,
            currentUserId: data?.userId,
            displayName: data?.displayName,
            phone: data?.phone,
            email: data?.email,
          });
        })
      );
  }

  supportLogin(body: LoginDTO): Observable<AuthLogin> {
    return this.api
      .post<AuthLogin>(API.SA_LOGIN, {
        body: body,
      })
      .pipe(
        tap((data) => {
          const uid = data?.userId;
          if (data?.accessToken) {
            // Save vào store
            this.store.updateSupport({
              accessToken: data?.accessToken,
              refreshToken: data?.refreshToken,
              userId: data?.userId,
              isSessionTimeout: false,
              supportRole: data?.supportRole,
              isSupported: data?.isSupported,
            });
          }
          const business = data?.business;
          this.appRepo.update({
            business: business,
            currentBusinessId: business?.id,
            currentUserId: data?.userId,
            displayName: data?.displayName,
            phone: data?.phone,
            email: data?.email,
          });
        })
      );
  }

  phoneCheck(body: PhoneCheckDTO): Observable<AuthPhoneCheck> {
    return this.api
      .post<AuthPhoneCheck>(API.PHONE_CHECK, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  businessSignup(body: businessSignUpDTO): Observable<AuthBusinessSignUp> {
    return this.api
      .post<AuthBusinessSignUp>(API.BUSINESS_SIGNUP, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  businessSignupVerify(body: businessSignUpVerifyDTO): Observable<AuthLogin> {
    return this.api
      .post<AuthLogin>(API.BUSINESS_VERIFY, {
        body: body,
      })
      .pipe(
        tap((data) => {
          if (data?.accessToken) {
            // Save vào store
            this.store.update({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
              userId: data.userId,
              isSessionTimeout: false,
            });
          }
          const business = data?.business;
          this.appRepo.update({
            business: business,
            currentBusinessId: business?.id,
            currentUserId: data?.userId,
            displayName: data?.displayName,
            phone: data?.phone,
            email: data?.email,
          });
        })
      );
  }

  businessSignupResendSMS(
    body: businessSignUpResendDTO
  ): Observable<AuthBusinessSignUp> {
    return this.api
      .post<AuthBusinessSignUp>(API.BUSINESS_RESEND, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  businessSignupResendCall(
    body: businessSignUpResendDTO
  ): Observable<AuthBusinessSignUp> {
    return this.api
      .post<AuthBusinessSignUp>(API.BUSINESS_RESEND, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  businessSetPassWord(body: SetPassDTO): Observable<AuthPhoneCheck> {
    return this.api
      .post<AuthPhoneCheck>(API.BUSINESS_SET_PASS, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  businessSetPassWordIsValid(body: SetPassIsValidDTO): Observable<any> {
    return this.api
      .post<any>(API.BUSINESS_SET_PASS_ISVALID, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  businessSetPassWordResend(body: SetPassResendDTO): Observable<any> {
    return this.api
      .post<any>(API.BUSINESS_SET_PASS_RESEND, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  businessSetPassWordVerify(body: SetPassVerifyDTO): Observable<AuthLogin> {
    return this.api
      .post<AuthLogin>(API.BUSINESS_SET_PASS_VERIFY, {
        body: body,
      })
      .pipe(
        tap((data) => {
          if (data?.accessToken) {
            // Save vào store
            this.store.update({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
              userId: data.userId,
              isSessionTimeout: false,
            });
          }
          const business = data?.business;
          this.appRepo.update({
            business: business,
            currentBusinessId: business?.id,
            currentUserId: data?.userId,
            displayName: data?.displayName,
            phone: data?.phone,
            email: data?.email,
          });
        })
      );
  }

  businessForgotPassWord(body: ForgotPassDTO): Observable<AuthPhoneCheck> {
    return this.api
      .post<AuthPhoneCheck>(API.BUSINESS_FORGOT_PASSWORD, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  businessForgotPassWordIsValid(body: ForgotPassIsValidDTO): Observable<any> {
    return this.api
      .post<any>(API.BUSINESS_FORGOT_PASSWORD_ISINVALID, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  businessForgotPassWordResend(body: ForgotPassResendDTO): Observable<any> {
    return this.api
      .post<any>(API.BUSINESS_FORGOT_PASSWORD_RESEND, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  businessForgotPassWordVerify(body: ForgotPassVerifyDTO): Observable<any> {
    return this.api
      .post<any>(API.BUSINESS_FORGOT_PASSWORD_VERIFY, {
        body: body,
      })
      .pipe(
        tap((data) => {
        })
      );
  }

  refreshToken(token: RefreshTokenDTO): Observable<LoginResponseDTO> {
    return this.api
      .post<LoginResponseDTO>(API.AUTH_REFRESH_TOKEN, {
        body: token,
      })
      .pipe(
        tap((data) => {
          const uid = data?.userId;
          if (data?.accessToken) {
            this.store.update({
              accessToken: data.accessToken,
              refreshToken: data.refreshToken,
              // expires: data.expires,
              userId: data.userId,
              isSessionTimeout: false,
            });
          }
        })
      );
  }

  logout() {
    // TODO: Call api logout to remove token
    this.store.update({ isSessionTimeout: false });
    getRegistry().forEach((store) => store.reset());
    localStorage.removeItem('IS_SHOW_DIFF_TIMEZONE');
    this.store.update({
      accessToken: '',
      refreshToken: '',
      userId: '',
    });
  }

  getUserProfile() {
    return this.api.get<any>(`${API.USER_PROFILE}`).pipe(
      tap((data) => {
        this.appRepo.update({
          currentUserId: data?.userId,
          displayName: data?.displayName,
          phone: data?.phone,
          email: data?.email,
        });
      })
    );
  }
}
