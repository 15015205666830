<div class="flex flex-col max-w-[344px] w-full">
    <div class=" absolute right-4 top-2">
      <a mat-icon-button matTooltip="{{ 'timeZoneDialog.CLOSE' | transloco }}" (click)="onCloseDialog()">
        <mat-icon class="text-black" [svgIcon]="'heroicons_outline:x-mark'"></mat-icon>
      </a>
    </div>

    <div class="flex flex-col w-full mt-8">
      <div class="bg-[#FEF3EB] text-[#0000008f] p-4 rounded-[12px] flex item-center ">
        <mat-icon class="icon-size-5" [svgIcon]="'icon-warning'"></mat-icon>
        <div class="pl-2">{{'timeZoneDialog.DATES_AND_TIMES_WILL_BE_SAVED_USING_THE_TIME_ZONE' | transloco}} {{businessTimeZone}}</div>
      </div>
      <div class="mt-4 flex flex-row justify-end ">
        <button (click)="onCloseDialog()" mat-flat-button type="button" class="bg-[#FF4F0F] text-[#ffffff] w-full py-3 rounded-[8px] lg:hover:opacity-80">
          {{'timeZoneDialog.ACKNOWLEDGE' | transloco}}
        </button>
      </div>
    </div>
    
</div>
