import { Injectable } from '@angular/core';
import { createStore } from '@ngneat/elf';
import {
  selectAllEntitiesApply,
  withEntities,
} from '@ngneat/elf-entities';
import { Approve } from './approve.model';

const store = createStore(
  { name: 'approve' },
  withEntities<Approve>()
);

@Injectable({ providedIn: 'root' })
export class ApproveRepository {
  constructor() {}

  approve$ = store.pipe(selectAllEntitiesApply({}));

  update(approve: Approve) {
    store.update((state) => ({ ...state, ...approve }));
  }

  get store() {
    return store;
  }
}
