import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppRoutes } from '@sticky/config';

@Injectable({
  providedIn: 'root'
})
export class OffersRedirectGuard implements CanActivate {

  constructor(private router: Router) {}

  canActivate(): boolean {
    this.router.navigate([`${AppRoutes.vouchers}`]);
    return false;
  }
}