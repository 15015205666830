export interface IconsLibrary {
    key?: string
    val?: string
  }
  
  export const IconsLibrary: IconsLibrary[] = [
    { key: 'color-bang-tin', val: 'color-bang-tin' },
    { key: 'color-bao-tri', val: 'color-bao-tri' },
    { key: 'color-cay-thong-giang-sinh', val: 'color-cay-thong-giang-sinh' },
    { key: 'color-cho-phep-mang-theo-dong-vat', val: 'color-cho-phep-mang-theo-dong-vat' },
    { key: 'color-chuc-mung', val: 'color-chuc-mung' },
    { key: 'color-ghi-chu', val: 'color-ghi-chu' },
    { key: 'color-khan-cap', val: 'color-khan-cap' },
    { key: 'color-le-hoi-giang-sinh', val: 'color-le-hoi-giang-sinh' },
    { key: 'color-lich', val: 'color-lich' },
    { key: 'color-lien-he-voi-cua-hang', val: 'color-lien-he-voi-cua-hang' },
    { key: 'color-neu-y-kien', val: 'color-neu-y-kien' },
    { key: 'color-new', val: 'color-new' },
    { key: 'color-noi-bat', val: 'color-noi-bat' },
    { key: 'color-promotion-hotdeal-top-highlights', val: 'color-promotion-hotdeal-top-highlights' },
    { key: 'color-qua-tang', val: 'color-qua-tang' },
    { key: 'color-quet-ma-qr', val: 'color-quet-ma-qr' },
    { key: 'color-sinh-nhat', val: 'color-sinh-nhat' },
    { key: 'color-thong-bao', val: 'color-thong-bao' },
    { key: 'color-xin-loi-vi-su-bat-tien', val: 'color-xin-loi-vi-su-bat-tien' },
    { key: 'color-yeu-thich', val: 'color-yeu-thich' },
  ]
  export const EmbeddedIconsLibrary: IconsLibrary[] = [
    { key: 'alert-filled-light', val: 'alert-filled-light' },
    { key: 'alert-filled', val: 'alert-filled' },
    { key: 'alert-regular-light', val: 'alert-regular-light' },
    { key: 'alert-regular', val: 'alert-regular' },
    { key: 'balloon-chat', val: 'balloon-chat' },
    { key: 'bell-alert-light', val: 'bell-alert-light' },
    { key: 'bell-alert', val: 'bell-alert' },
    { key: 'chat-cc-fille-light', val: 'chat-cc-fille-light' },
    { key: 'chat-cc-fille', val: 'chat-cc-fille' },
    { key: 'chat-cc-regular-light', val: 'chat-cc-regular-light' },
    { key: 'chat-cc-regular', val: 'chat-cc-regular' },
    { key: 'chat-filled-light', val: 'chat-filled-light' },
    { key: 'chat-filled', val: 'chat-filled' },
    { key: 'chat-regular-light', val: 'chat-regular-light' },
    { key: 'chat-regular', val: 'chat-regular' },
    { key: 'chat-smile-filled-light', val: 'chat-smile-filled-light' },
    { key: 'chat-smile-filled', val: 'chat-smile-filled' },
    { key: 'chat-smile-regular-light', val: 'chat-smile-regular-light' },
    { key: 'chat-smile-regular', val: 'chat-smile-regular' },
    { key: 'fire-light', val: 'fire-light' },
    { key: 'hand-shake', val: 'hand-shake' },
    { key: 'headset-filled-light', val: 'headset-filled-light' },
    { key: 'headset-filled', val: 'headset-filled' },
    { key: 'headset-regular-light', val: 'headset-regular-light' },
    { key: 'headset-regular', val: 'headset-regular' },
    { key: 'mail-alert-filled', val: 'mail-alert-filled' },
    { key: 'mail-alert-regular', val: 'mail-alert-regular' },
    { key: 'mail-filled-light', val: 'mail-filled-light' },
    { key: 'mail-filled', val: 'mail-filled' },
    { key: 'megaphone', val: 'megaphone' },
    { key: 'smiley-filled-light', val: 'smiley-filled-light' },
    { key: 'smiley-filled', val: 'smiley-filled' },
    { key: 'smiley-regular-light', val: 'smiley-regular-light' },
    { key: 'smiley-regular', val: 'smiley-regular' },
    { key: 'telephone-receiver', val: 'telephone-receiver' },
    { key: 'telephone', val: 'telephone' },
    { key: 'victory-hand', val: 'victory-hand' },
    { key: 'waving-hand-left', val: 'waving-hand-left' },
    { key: 'waving-hand-right', val: 'waving-hand-right' },
  ]
  