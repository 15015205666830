import { Injectable } from '@angular/core';
import { createStore, select, withProps } from '@ngneat/elf';
import { selectAllEntitiesApply, withEntities } from '@ngneat/elf-entities';
import { BusinessFilter, BusinessMeta, BusinessSA, DashboardSupport } from './business-supper-admin.model';
import { combineLatest, switchMap } from 'rxjs';
import { state } from '@angular/animations';

const store = createStore({ name: 'businessSA' },
  withProps<BusinessMeta>({}),
  withEntities<BusinessSA>(),
  withProps<DashboardSupport>({}));
@Injectable({ providedIn: 'root' })
export class BusinessSARepository {
  constructor() {}
  type$ = store.pipe(select((state:any) => state.busId));
  businessFilter$ = store.pipe((select(state=>state.filters)))
  businessSA$ = combineLatest([this.businessFilter$]).pipe(
    switchMap(([filter]) => {
      return store.pipe(
        selectAllEntitiesApply(
          { filterEntity: (entity) => this.filterEntity(entity, filter) },
        )
      )
    })
  );

  update(users) {
    store.update(state => ({ ...state, ...users }));
  }

  updateBusinessSupportID(id) {
    store.update((state) => ({
      ...state,
      busId: id,
    }));
  }

  updateBusinessSupport(result) {
    store.update((state) => ({
      ...state,
      business_selected: result,
    }));
  }

  dashboardScannedCode$ = store.pipe(
    select((state) => {
      return state.scanned_codes || 0;
    })
  );
  dashboardScannedCodeMonth$ = store.pipe(
    select((state) => {
      return state.scanned_codes_month || 0;
    })
  );

  dashboardRewardsRedeemed$ = store.pipe(
    select((state) => {
      return state.rewards_redeemed || 0;
    })
  );

  dashboardGivenPoints$ = store.pipe(
    select((state) => {
      return state.given || 0;
    })
  );
  dashboardNewReturn$ = store.pipe(
    select((state) => {
      return state.business_new_return || 0;
    })
  );

  dashboardNoCustomers$ = store.pipe(
    select((state) => {
      return state.no_customers || 0;
    })
  );

  dashboardBusinessStats$ = store.pipe(
    select((state) => {
      return state.business_stats;
    })
  );

  topRewards$ = store.pipe(
    select((state) => {
      return state.top_rewards;
    })
  );

  topCustomersPoints$ = store.pipe(
    select((state) => {
      return state.top_customers_points;
    })
  );

  dashboardBusinessStatsReferral$ = store.pipe(
    select((state) => {
      return state.business_stats_referral;
    })
  );
  dashboardChartsOverview$ = store.pipe(
    select((state) => {
      return state.code_rewards;
    })
  );

  dashboardChartsCustomers$ = store.pipe(
    select((state) => {
      return state.new_return_guest;
    })
  );

  get supportBus (){
    return store.getValue()
  }

  get store() {
    return store;
  }

  getFilter(){
    return this.store.getValue().filters;
  }

  filterEntity(entity:  BusinessSA, filter?: BusinessFilter) {
    if (!filter) return true;
    if (filter.text && filter.text != ''){
      if(entity?.name?.toLowerCase().indexOf(filter.text.toLowerCase()) === -1 && entity?.phone?.toLowerCase().indexOf(filter.text.toLowerCase()) === -1){
        return false;
      }
    }
    return true;
  }

  setFilter(filter?: BusinessFilter){
    let filters = filter;
    if(filter){
       filters= { ...this.getFilter(), ...filter }
    }
    store.update(state => ({
      ...state,
      filters: filters
    }))
  }
}
