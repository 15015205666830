import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatOptionModule } from '@angular/material/core';
import {
  MAT_DIALOG_DATA,
  MatDialogModule,
  MatDialogRef,
} from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterOutlet, RouterLink } from '@angular/router';
import {
  TRANSLOCO_SCOPE,
  TranslocoModule,
} from '@ngneat/transloco';
import { LanguagesSupport } from '@sticky/config';
import {Logger } from '@sticky/services';
import { AppRepository } from '@sticky/state/app';
import { BusinessSARepository } from '@sticky/state/business-supper-admin';
import { PipesModule } from '@sticky/util-common/pipes.module';
import { Subject, debounceTime, takeUntil } from 'rxjs';
const log = new Logger('ListBusinessDialogComponent');

const loader = LanguagesSupport.reduce((acc: any, lang) => {
  acc[lang.id] = () => import(`./i18n/${lang.id}.json`);
  return acc;
}, {});

@Component({
  selector: 'list-business-dialog',
  templateUrl: './list-business-dialog.component.html',
 
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styles: [
    `
      .list-business-dialog {
        .list-business-content {
          max-height: 20vh !important;
          max-height: 33vh !important;
          overflow: auto !important;
        }
        .content-dialog {
          overflow: auto !important;
          max-height: calc(45vh - 80px) !important;
          @media (max-width: 600px) {
            max-height: unset !important;
          }
        }
        .btn-minus,
        .btn-plus {
          border: 1px solid #ebebeb;
        }
        @media ((min-width: 510px) and (max-width: 599px)) {
            min-width: 420px !important;
        }
        @media ((min-width: 450px) and (max-width: 509px)) {
            min-width: 360px !important;
        }
        @media ((min-width: 450px) and (max-width: 509px)) {
            min-width: 360px !important;
        }
        @media ((min-width: 401px) and (max-width: 449px)) {
          min-width: 320px !important;
        }
        @media ((min-width: 360px) and (max-width: 400px)) {
            min-width: 290px !important;
        }
      }
    `,
  ],
  providers: [
    {
      provide: TRANSLOCO_SCOPE,
      useValue: {
        scope: 'busList',
        loader,
      },
    },
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatFormFieldModule,
    MatOptionModule,
    RouterOutlet,
    PipesModule,
    MatIconModule,
    MatInputModule,
    MatButtonModule,
    MatSelectModule,
    MatDialogModule,
    RouterLink,
    MatTooltipModule,
    TranslocoModule,
  ],
})
export class ListBusinessDialogComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject();
  submitted: boolean = false;
  locale: string;
  searchInputControl: UntypedFormControl = new UntypedFormControl();
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  isSearch: boolean = false;
  dataLoadMore: any;
  isLoading: boolean = false;
  businesses: any[]=[]
  selectedBus
  busIdSelected
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ListBusinessDialogComponent>,
    public appRepo: AppRepository,
    private changeDetectorRef: ChangeDetectorRef,
    public repo: BusinessSARepository,
  ) {
    this.businesses = data?.bus
    if(!data?.busID) return
    this.busIdSelected = data?.busID
  }
  ngOnInit(): void {
    this.repo.setFilter({ text: '' });
    this.searchInputControl.valueChanges
      .pipe(takeUntil(this.destroy$), debounceTime(300))
      .subscribe((searchText) => {
        this.changeDetectorRef.markForCheck();
        if (searchText) {
          this.isSearch = true;
        } else {
          this.isSearch = false;
        }
        this.repo.setFilter({
          text: searchText,
        });
        this.changeDetectorRef.markForCheck();
      });

      this.repo.businessSA$.subscribe((v) => {
        this.businesses = v;
        v?.sort((a,b) => a?.name?.trim()?.toUpperCase() < b?.name?.trim()?.toUpperCase() ? -1 : 1);
        this.changeDetectorRef.markForCheck();
      });
  }

  ngOnDestroy(): void
  {
      this._unsubscribeAll.next(null);
      this._unsubscribeAll.complete();
  }

  onVisitingBusiness(item){
    this.dialogRef.close(item)
  }


}
