import { el } from 'date-fns/locale';
import { NgIf, CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { ActivatedRoute, NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { FuseFullscreenComponent } from '@fuse/components/fullscreen';
import { FuseLoadingBarComponent } from '@fuse/components/loading-bar';
import {
  FuseNavigationService,
  FuseVerticalNavigationComponent,
} from '@fuse/components/navigation';
import { FuseMediaWatcherService } from '@fuse/services/media-watcher';
import { appRoutes } from '@sticky/config';
import { NavigationService } from '@sticky/navigation/navigation.service';
import { Navigation } from '@sticky/navigation/navigation.types';
import { AppRepository } from '@sticky/state/app';
import { Approve, ApproveRepository, ApproveService } from '@sticky/state/approve';
import { BusinessService } from '@sticky/state/business';
import { UserService } from '@sticky/user/user.service';
import { User } from '@sticky/user/user.types';
import { ApproveCommonComponent } from 'app/layout/common/approve/approve.component';
import { LanguagesComponent } from 'app/layout/common/languages/languages.component';
import { MessagesComponent } from 'app/layout/common/messages/messages.component';
import { NotificationsComponent } from 'app/layout/common/notifications/notifications.component';
import { QuickChatComponent } from 'app/layout/common/quick-chat/quick-chat.component';
import { SearchComponent } from 'app/layout/common/search/search.component';
import { ShortcutsComponent } from 'app/layout/common/shortcuts/shortcuts.component';
import { UserComponent } from 'app/layout/common/user/user.component';
import { Subject, filter, takeUntil } from 'rxjs';
import { AuthRepository, AuthService } from '@sticky/state/auth';
import { BusinessSARepository, BusinessSAService } from '@sticky/state/business-supper-admin';
import { MatDialog } from '@angular/material/dialog';
import { ListBusinessDialogComponent } from 'app/modules/admin/business/components/list-business/list-business-dialog.component';
import { Business } from '@sticky/state/campaigns';
import { HotToastService } from '@ngneat/hot-toast';

@Component({
  selector: 'classy-layout',
  templateUrl: './classy.component.html',
  encapsulation: ViewEncapsulation.None,
  standalone: true,
  imports: [
    FuseLoadingBarComponent,
    FuseVerticalNavigationComponent,
    NotificationsComponent,
    UserComponent,
    NgIf,
    MatIconModule,
    MatButtonModule,
    CommonModule,
    LanguagesComponent,
    FuseFullscreenComponent,
    SearchComponent,
    ShortcutsComponent,
    MessagesComponent,
    RouterOutlet,
    QuickChatComponent,
    ApproveCommonComponent
  ],
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
  isScreenSmall: boolean;
  navigation: Navigation;
  user: User;
  private _unsubscribeAll: Subject<any> = new Subject<any>();
  pagination = {
    take: 50,
    page: 0,
    firstPage: 0,
  };
  listApprove: Approve[];
  approveUrl: string;
  redirectURL
  busSelected:Business
  busID: string
  businesses: any[]=[];
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _navigationService: NavigationService,
    private _userService: UserService,
    private _fuseMediaWatcherService: FuseMediaWatcherService,
    private _fuseNavigationService: FuseNavigationService,
    public _appRepository: AppRepository,
    public authRepo: AuthRepository,
    public busService: BusinessService,
    private _approveService: ApproveService,
    private cdr: ChangeDetectorRef,
    public repo: ApproveRepository,
    public busRepo: BusinessSARepository,
    private busSupport: BusinessSAService,
    @Inject(AuthService) private authService: AuthService,
    private toast: HotToastService,
    private dialog: MatDialog,

  ) {

   
    this.activatedRoute.data.subscribe(({ res }) => {
      this.redirectURL = this.activatedRoute.snapshot['_routerState'].url
      this.cdr.markForCheck()
    });
    this.repo.approve$.subscribe((v: any) => {
      this.listApprove = v;
    });
    this.approveUrl = this.router.url
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.redirectURL = this.activatedRoute.snapshot['_routerState'].url
        if(event?.url){
          this.approveUrl = event?.url
        }
    });
    // this.getListApprove();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Getter for current year
   */
  get currentYear(): number {
    return new Date().getFullYear();
  }

  get business() {
    return this._appRepository?.business;
  }

  get role() {
    return this.authRepo.role
  }

  get supportRole() {
    return this.authRepo.supportRole
  }
  get isSupported() {
    return this.authRepo.isSupported
  }
  get username() {
    return this._appRepository.username
  }

  get getApproveUrl(){
    if(this.approveUrl === `/${appRoutes.APPROVE}`){
      return false;
    }else{
      return true;
    }
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Lifecycle hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  /**
   * On init
   */
  ngOnInit(): void {
    // Subscribe to navigation data
    this._navigationService.navigation$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((navigation: Navigation) => {
        this.navigation = navigation;
      });
    // Subscribe to media changes
    this._fuseMediaWatcherService.onMediaChange$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe(({ matchingAliases }) => {
        // Check if the screen is small
        this.isScreenSmall = !matchingAliases.includes('lg');
      });
      if(this.supportRole == 'CUSTOMER_SERVICE') {
        this.getListBusiness()
      }
  }

  /**
   * On destroy
   */
  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Toggle navigation
   *
   * @param name
   */
  getListApprove() {
    this._approveService
      .getAllApprove({
        page: this.pagination.page,
        take: this.pagination.take,
      })
      .subscribe({
        next: (value: any) => {
        },
        error: (err) => {
          
        },
      });
  }
  toggleNavigation(name: string): void {
    // Get the navigation
    const navigation =
      this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(
        name
      );

    if (navigation) {
      // Toggle the opened status
      navigation.toggle();
    }
  }
  getListBusiness() {
    this.busSupport.getAllBusiness().subscribe({
      next: (value: any) => {
        this.businesses = value;
        value.sort((a,b) => a?.name?.trim()?.toUpperCase() < b?.name?.trim()?.toUpperCase() ? -1 : 1);
        this.cdr.markForCheck();
        },
        error: (err) => {
          this.router.navigate([`/${appRoutes.GET_STARTED}`], {
            relativeTo: this.activatedRoute,
          });
          this.authService.logout();
          this.toast.error(err?.error?.message)
        },
    });
  }
  onOpenListBusinessDialog(){
    const dialog = this.dialog.open(ListBusinessDialogComponent, {
      panelClass: 'list-business-dialog',
      data: {
        bus:this.businesses,
        busID: this._appRepository.currentBid
      }
      
    });
    dialog.afterClosed().subscribe((result) => {
      this.busID = result?.id
      this.busSelected = result || this.busRepo.supportBus.business_selected
      if(result == undefined || !result) return this.busRepo.supportBus.business_selected?.id
      this.busRepo.updateBusinessSupportID(result?.id);
      this.busRepo.updateBusinessSupport(result);
      this.getBusinessSupportDetails(this.busSelected?.id)
      this.cdr.detectChanges()
      });
  }

 

  getBusinessSupportDetails(bid) {
    this.busSupport.getBusinessSupportDetails(bid).subscribe({
      next: (value: any) => {
        this.router.navigate(['/dashboard'], {relativeTo: this.activatedRoute});
        window.location.reload();
        this.cdr.markForCheck();
        },
        error: (err) => {
          this.router.navigate([`/${appRoutes.GET_STARTED}`], {
            relativeTo: this.activatedRoute,
          });
          this.toast.error(err?.error?.error)
        },
    });
  }
}
