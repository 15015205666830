/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';

import { ApproveRepository } from './approve.repository';
import { HttpParams } from '@angular/common/http';
import { Approve } from './approve.model';
import { Observable, tap } from 'rxjs';
import { API, APIService } from '@sticky/common';
import {
  deleteEntities,
  upsertEntities,
} from '@ngneat/elf-entities';
import { ApproveUpdateDTO, RefuseUpdateDTO } from './approve.dto';

/**
 * Đây là based service dành cho xử lý chung
 */
@Injectable({ providedIn: 'root' })
export class ApproveService {
  constructor(
    private readonly api: APIService,
    private store: ApproveRepository
  ) {}

  getAllApprove(params?: any): Observable<Approve> {
    let pa = new HttpParams();
    if (params) {
      Object.keys(params).forEach(function (key) {
        pa = pa.append(key, params[key]);
      });
    }
    return this.api
      .get<any>(`${API.PENDING_APPROVE}`, {
        params: pa,
      })
      .pipe(
        tap((data) => {
          this.store.store.update(upsertEntities(data?.data));
        })
      );
  }

  refuse(body: RefuseUpdateDTO, id): Observable<Approve> {
    return this.api
      .post<Approve>(API.REFUSE, {
        body: body,
      })
      .pipe(
        tap((data) => {
          if (data) {
            this.store.store.update(deleteEntities(id));
          }
        })
      );
  }

  approve(body: RefuseUpdateDTO, id): Observable<Approve> {
    return this.api
      .post<Approve>(API.APPROVE, {
        body: body,
      })
      .pipe(
        tap((data) => {
          if (data) {
            this.store.store.update(deleteEntities(id));
          }
        })
      );
  }
}
