export const countries = [
  {
    iso: 'vn',
    name: 'Vietnam',
    code: '+84',
    flagImagePos: '-1px -1259px',
  },
  {
    iso: 'vn',
    name: 'Vietnam',
    code: '+84',
    flagImagePos: '-1px -1259px',
  },
  {
    iso: 'us',
    name: 'United States',
    code: '+1',
    flagImagePos: '-1px -69px',
  },
  {
    iso: 'af',
    name: 'Afghanistan',
    code: '+93',
    flagImagePos: '-1px -3180px',
  },
  {
    iso: 'al',
    name: 'Albania',
    code: '+355',
    flagImagePos: '-1px -1310px',
  },
  {
    iso: 'dz',
    name: 'Algeria',
    code: '+213',
    flagImagePos: '-1px -681px',
  },
  {
    iso: 'as',
    name: 'American Samoa',
    code: '+1',
    flagImagePos: '-1px -2058px',
  },
  {
    iso: 'ad',
    name: 'Andorra',
    code: '+376',
    flagImagePos: '-1px -766px',
  },
  {
    iso: 'ao',
    name: 'Angola',
    code: '+244',
    flagImagePos: '-1px -2636px',
  },
  {
    iso: 'ai',
    name: 'Anguilla',
    code: '+1',
    flagImagePos: '-1px -2687px',
  },
  {
    iso: 'ag',
    name: 'Antigua & Barbuda',
    code: '+1',
    flagImagePos: '-1px -1140px',
  },
  {
    iso: 'ar',
    name: 'Argentina',
    code: '+54',
    flagImagePos: '-1px -3282px',
  },
  {
    iso: 'am',
    name: 'Armenia',
    code: '+374',
    flagImagePos: '-1px -205px',
  },
  {
    iso: 'aw',
    name: 'Aruba',
    code: '+297',
    flagImagePos: '-1px -1021px',
  },
  {
    iso: 'ac',
    name: 'Ascension Island',
    code: '+247',
    flagImagePos: '-1px -86px',
  },
  {
    iso: 'au',
    name: 'Australia',
    code: '+61',
    flagImagePos: '-1px -2279px',
  },
  {
    iso: 'at',
    name: 'Austria',
    code: '+43',
    flagImagePos: '-1px -1735px',
  },
  {
    iso: 'az',
    name: 'Azerbaijan',
    code: '+994',
    flagImagePos: '-1px -1599px',
  },
  {
    iso: 'bs',
    name: 'Bahamas',
    code: '+1',
    flagImagePos: '-1px -460px',
  },
  {
    iso: 'bh',
    name: 'Bahrain',
    code: '+973',
    flagImagePos: '-1px -1956px',
  },
  {
    iso: 'bd',
    name: 'Bangladesh',
    code: '+880',
    flagImagePos: '-1px -2364px',
  },
  {
    iso: 'bb',
    name: 'Barbados',
    code: '+1',
    flagImagePos: '-1px -2075px',
  },
  {
    iso: 'by',
    name: 'Belarus',
    code: '+375',
    flagImagePos: '-1px -1412px',
  },
  {
    iso: 'be',
    name: 'Belgium',
    code: '+32',
    flagImagePos: '-1px -1px',
  },
  {
    iso: 'bz',
    name: 'Belize',
    code: '+501',
    flagImagePos: '-1px -613px',
  },
  {
    iso: 'bj',
    name: 'Benin',
    code: '+229',
    flagImagePos: '-1px -1684px',
  },
  {
    iso: 'bm',
    name: 'Bermuda',
    code: '+1',
    flagImagePos: '-1px -2585px',
  },
  {
    iso: 'bt',
    name: 'Bhutan',
    code: '+975',
    flagImagePos: '-1px -2483px',
  },
  {
    iso: 'bo',
    name: 'Bolivia',
    code: '+591',
    flagImagePos: '-1px -2177px',
  },
  {
    iso: 'ba',
    name: 'Bosnia & Herzegovina',
    code: '+387',
    flagImagePos: '-1px -2092px',
  },
  {
    iso: 'bw',
    name: 'Botswana',
    code: '+267',
    flagImagePos: '-1px -3724px',
  },
  {
    iso: 'br',
    name: 'Brazil',
    code: '+55',
    flagImagePos: '-1px -1004px',
  },
  {
    iso: 'io',
    name: 'British Indian Ocean Territory',
    code: '+246',
    flagImagePos: '-1px -86px',
  },
  {
    iso: 'vg',
    name: 'British Virgin Islands',
    code: '+1',
    flagImagePos: '-1px -1854px',
  },
  {
    iso: 'bn',
    name: 'Brunei',
    code: '+673',
    flagImagePos: '-1px -2228px',
  },
  {
    iso: 'bg',
    name: 'Bulgaria',
    code: '+359',
    flagImagePos: '-1px -3537px',
  },
  {
    iso: 'bf',
    name: 'Burkina Faso',
    code: '+226',
    flagImagePos: '-1px -953px',
  },
  {
    iso: 'bi',
    name: 'Burundi',
    code: '+257',
    flagImagePos: '-1px -2551px',
  },
  {
    iso: 'kh',
    name: 'Cambodia',
    code: '+855',
    flagImagePos: '-1px -290px',
  },
  {
    iso: 'cm',
    name: 'Cameroon',
    code: '+237',
    flagImagePos: '-1px -2806px',
  },
  {
    iso: 'ca',
    name: 'Canada',
    code: '+1',
    flagImagePos: '-1px -1803px',
  },
  {
    iso: 'cv',
    name: 'Cape Verde',
    code: '+238',
    flagImagePos: '-1px -3639px',
  },
  {
    iso: 'bq',
    name: 'Caribbean Netherlands',
    code: '+599',
    flagImagePos: '-1px -3741px',
  },
  {
    iso: 'ky',
    name: 'Cayman Islands',
    code: '+1',
    flagImagePos: '-1px -375px',
  },
  {
    iso: 'cf',
    name: 'Central African Republic',
    code: '+236',
    flagImagePos: '-1px -2466px',
  },
  {
    iso: 'td',
    name: 'Chad',
    code: '+235',
    flagImagePos: '-1px -1055px',
  },
  {
    iso: 'cl',
    name: 'Chile',
    code: '+56',
    flagImagePos: '-1px -1752px',
  },
  {
    iso: 'cn',
    name: 'China',
    code: '+86',
    flagImagePos: '-1px -1072px',
  },
  {
    iso: 'co',
    name: 'Colombia',
    code: '+57',
    flagImagePos: '-1px -409px',
  },
  {
    iso: 'km',
    name: 'Comoros',
    code: '+269',
    flagImagePos: '-1px -1871px',
  },
  {
    iso: 'cg',
    name: 'Congo - Brazzaville',
    code: '+242',
    flagImagePos: '-1px -2398px',
  },
  {
    iso: 'cd',
    name: 'Congo - Kinshasa',
    code: '+243',
    flagImagePos: '-1px -1990px',
  },
  {
    iso: 'ck',
    name: 'Cook Islands',
    code: '+682',
    flagImagePos: '-1px -3112px',
  },
  {
    iso: 'cr',
    name: 'Costa Rica',
    code: '+506',
    flagImagePos: '-1px -2857px',
  },
  {
    iso: 'ci',
    name: 'Côte d’Ivoire',
    code: '+225',
    flagImagePos: '-1px -2194px',
  },
  {
    iso: 'hr',
    name: 'Croatia',
    code: '+385',
    flagImagePos: '-1px -1174px',
  },
  {
    iso: 'cu',
    name: 'Cuba',
    code: '+53',
    flagImagePos: '-1px -987px',
  },
  {
    iso: 'cw',
    name: 'Curaçao',
    code: '+599',
    flagImagePos: '-1px -3758px',
  },
  {
    iso: 'cy',
    name: 'Cyprus',
    code: '+357',
    flagImagePos: '-1px -732px',
  },
  {
    iso: 'cz',
    name: 'Czechia',
    code: '+420',
    flagImagePos: '-1px -3095px',
  },
  {
    iso: 'dk',
    name: 'Denmark',
    code: '+45',
    flagImagePos: '-1px -1820px',
  },
  {
    iso: 'dj',
    name: 'Djibouti',
    code: '+253',
    flagImagePos: '-1px -2874px',
  },
  {
    iso: 'dm',
    name: 'Dominica',
    code: '+1',
    flagImagePos: '-1px -3350px',
  },
  {
    iso: 'do',
    name: 'Dominican Republic',
    code: '+1',
    flagImagePos: '-1px -2007px',
  },
  {
    iso: 'ec',
    name: 'Ecuador',
    code: '+593',
    flagImagePos: '-1px -1531px',
  },
  {
    iso: 'eg',
    name: 'Egypt',
    code: '+20',
    flagImagePos: '-1px -3027px',
  },
  {
    iso: 'sv',
    name: 'El Salvador',
    code: '+503',
    flagImagePos: '-1px -2160px',
  },
  {
    iso: 'gq',
    name: 'Equatorial Guinea',
    code: '+240',
    flagImagePos: '-1px -1973px',
  },
  {
    iso: 'er',
    name: 'Eritrea',
    code: '+291',
    flagImagePos: '-1px -936px',
  },
  {
    iso: 'ee',
    name: 'Estonia',
    code: '+372',
    flagImagePos: '-1px -3333px',
  },
  {
    iso: 'sz',
    name: 'Eswatini',
    code: '+268',
    flagImagePos: '-1px -3129px',
  },
  {
    iso: 'et',
    name: 'Ethiopia',
    code: '+251',
    flagImagePos: '-1px -3367px',
  },
  {
    iso: 'fk',
    name: 'Falkland Islands (Islas Malvinas)',
    code: '+500',
    flagImagePos: '-1px -3809px',
  },
  {
    iso: 'fo',
    name: 'Faroe Islands',
    code: '+298',
    flagImagePos: '-1px -1429px',
  },
  {
    iso: 'fj',
    name: 'Fiji',
    code: '+679',
    flagImagePos: '-1px -2500px',
  },
  {
    iso: 'fi',
    name: 'Finland',
    code: '+358',
    flagImagePos: '-1px -2568px',
  },
  {
    iso: 'fr',
    name: 'France',
    code: '+33',
    flagImagePos: '-1px -324px',
  },
  {
    iso: 'gf',
    name: 'French Guiana',
    code: '+594',
    flagImagePos: '-1px -324px',
  },
  {
    iso: 'pf',
    name: 'French Polynesia',
    code: '+689',
    flagImagePos: '-1px -2262px',
  },
  {
    iso: 'ga',
    name: 'Gabon',
    code: '+241',
    flagImagePos: '-1px -1157px',
  },
  {
    iso: 'gm',
    name: 'Gambia',
    code: '+220',
    flagImagePos: '-1px -817px',
  },
  {
    iso: 'ge',
    name: 'Georgia',
    code: '+995',
    flagImagePos: '-1px -1123px',
  },
  {
    iso: 'de',
    name: 'Germany',
    code: '+49',
    flagImagePos: '-1px -3452px',
  },
  {
    iso: 'gh',
    name: 'Ghana',
    code: '+233',
    flagImagePos: '-1px -2891px',
  },
  {
    iso: 'gi',
    name: 'Gibraltar',
    code: '+350',
    flagImagePos: '-1px -341px',
  },
  {
    iso: 'gr',
    name: 'Greece',
    code: '+30',
    flagImagePos: '-1px -188px',
  },
  {
    iso: 'gl',
    name: 'Greenland',
    code: '+299',
    flagImagePos: '-1px -2347px',
  },
  {
    iso: 'gd',
    name: 'Grenada',
    code: '+1',
    flagImagePos: '-1px -3316px',
  },
  {
    iso: 'gp',
    name: 'Guadeloupe',
    code: '+590',
    flagImagePos: '-1px -511px',
  },
  {
    iso: 'gu',
    name: 'Guam',
    code: '+1',
    flagImagePos: '-1px -3265px',
  },
  {
    iso: 'gt',
    name: 'Guatemala',
    code: '+502',
    flagImagePos: '-1px -1208px',
  },
  {
    iso: 'gn',
    name: 'Guinea',
    code: '+224',
    flagImagePos: '-1px -3520px',
  },
  {
    iso: 'gw',
    name: 'Guinea-Bissau',
    code: '+245',
    flagImagePos: '-1px -2602px',
  },
  {
    iso: 'gy',
    name: 'Guyana',
    code: '+592',
    flagImagePos: '-1px -1038px',
  },
  {
    iso: 'ht',
    name: 'Haiti',
    code: '+509',
    flagImagePos: '-1px -392px',
  },
  {
    iso: 'hn',
    name: 'Honduras',
    code: '+504',
    flagImagePos: '-1px -2959px',
  },
  {
    iso: 'hk',
    name: 'Hong Kong',
    code: '+852',
    flagImagePos: '-1px -3707px',
  },
  {
    iso: 'hu',
    name: 'Hungary',
    code: '+36',
    flagImagePos: '-1px -902px',
  },
  {
    iso: 'is',
    name: 'Iceland',
    code: '+354',
    flagImagePos: '-1px -2704px',
  },
  {
    iso: 'in',
    name: 'India',
    code: '+91',
    flagImagePos: '-1px -2245px',
  },
  {
    iso: 'id',
    name: 'Indonesia',
    code: '+62',
    flagImagePos: '-1px -2653px',
  },
  {
    iso: 'ir',
    name: 'Iran',
    code: '+98',
    flagImagePos: '-1px -2738px',
  },
  {
    iso: 'iq',
    name: 'Iraq',
    code: '+964',
    flagImagePos: '-1px -851px',
  },
  {
    iso: 'ie',
    name: 'Ireland',
    code: '+353',
    flagImagePos: '-1px -2670px',
  },
  {
    iso: 'il',
    name: 'Israel',
    code: '+972',
    flagImagePos: '-1px -426px',
  },
  {
    iso: 'it',
    name: 'Italy',
    code: '+39',
    flagImagePos: '-1px -154px',
  },
  {
    iso: 'jm',
    name: 'Jamaica',
    code: '+1',
    flagImagePos: '-1px -2296px',
  },
  {
    iso: 'jp',
    name: 'Japan',
    code: '+81',
    flagImagePos: '-1px -528px',
  },
  {
    iso: 'jo',
    name: 'Jordan',
    code: '+962',
    flagImagePos: '-1px -1905px',
  },
  {
    iso: 'kz',
    name: 'Kazakhstan',
    code: '+7',
    flagImagePos: '-1px -1565px',
  },
  {
    iso: 'ke',
    name: 'Kenya',
    code: '+254',
    flagImagePos: '-1px -3605px',
  },
  {
    iso: 'ki',
    name: 'Kiribati',
    code: '+686',
    flagImagePos: '-1px -477px',
  },
  {
    iso: 'xk',
    name: 'Kosovo',
    code: '+383',
    flagImagePos: '-1px -3860px',
  },
  {
    iso: 'kw',
    name: 'Kuwait',
    code: '+965',
    flagImagePos: '-1px -3435px',
  },
  {
    iso: 'kg',
    name: 'Kyrgyzstan',
    code: '+996',
    flagImagePos: '-1px -2143px',
  },
  {
    iso: 'la',
    name: 'Laos',
    code: '+856',
    flagImagePos: '-1px -562px',
  },
  {
    iso: 'lv',
    name: 'Latvia',
    code: '+371',
    flagImagePos: '-1px -2619px',
  },
  {
    iso: 'lb',
    name: 'Lebanon',
    code: '+961',
    flagImagePos: '-1px -1616px',
  },
  {
    iso: 'ls',
    name: 'Lesotho',
    code: '+266',
    flagImagePos: '-1px -3010px',
  },
  {
    iso: 'lr',
    name: 'Liberia',
    code: '+231',
    flagImagePos: '-1px -2823px',
  },
  {
    iso: 'ly',
    name: 'Libya',
    code: '+218',
    flagImagePos: '-1px -137px',
  },
  {
    iso: 'li',
    name: 'Liechtenstein',
    code: '+423',
    flagImagePos: '-1px -1276px',
  },
  {
    iso: 'lt',
    name: 'Lithuania',
    code: '+370',
    flagImagePos: '-1px -1446px',
  },
  {
    iso: 'lu',
    name: 'Luxembourg',
    code: '+352',
    flagImagePos: '-1px -1922px',
  },
  {
    iso: 'mo',
    name: 'Macao',
    code: '+853',
    flagImagePos: '-1px -3554px',
  },
  {
    iso: 'mg',
    name: 'Madagascar',
    code: '+261',
    flagImagePos: '-1px -1667px',
  },
  {
    iso: 'mw',
    name: 'Malawi',
    code: '+265',
    flagImagePos: '-1px -2942px',
  },
  {
    iso: 'my',
    name: 'Malaysia',
    code: '+60',
    flagImagePos: '-1px -2517px',
  },
  {
    iso: 'mv',
    name: 'Maldives',
    code: '+960',
    flagImagePos: '-1px -800px',
  },
  {
    iso: 'ml',
    name: 'Mali',
    code: '+223',
    flagImagePos: '-1px -3469px',
  },
  {
    iso: 'mt',
    name: 'Malta',
    code: '+356',
    flagImagePos: '-1px -2041px',
  },
  {
    iso: 'mh',
    name: 'Marshall Islands',
    code: '+692',
    flagImagePos: '-1px -1463px',
  },
  {
    iso: 'mq',
    name: 'Martinique',
    code: '+596',
    flagImagePos: '-1px -239px',
  },
  {
    iso: 'mr',
    name: 'Mauritania',
    code: '+222',
    flagImagePos: '-1px -307px',
  },
  {
    iso: 'mu',
    name: 'Mauritius',
    code: '+230',
    flagImagePos: '-1px -2993px',
  },
  {
    iso: 'mx',
    name: 'Mexico',
    code: '+52',
    flagImagePos: '-1px -2755px',
  },
  {
    iso: 'fm',
    name: 'Micronesia',
    code: '+691',
    flagImagePos: '-1px -2313px',
  },
  {
    iso: 'md',
    name: 'Moldova',
    code: '+373',
    flagImagePos: '-1px -3690px',
  },
  {
    iso: 'mc',
    name: 'Monaco',
    code: '+377',
    flagImagePos: '-1px -1191px',
  },
  {
    iso: 'mn',
    name: 'Mongolia',
    code: '+976',
    flagImagePos: '-1px -3503px',
  },
  {
    iso: 'me',
    name: 'Montenegro',
    code: '+382',
    flagImagePos: '-1px -2976px',
  },
  {
    iso: 'ms',
    name: 'Montserrat',
    code: '+1',
    flagImagePos: '-1px -749px',
  },
  {
    iso: 'ma',
    name: 'Morocco',
    code: '+212',
    flagImagePos: '-1px -3214px',
  },
  {
    iso: 'mz',
    name: 'Mozambique',
    code: '+258',
    flagImagePos: '-1px -834px',
  },
  {
    iso: 'mm',
    name: 'Myanmar (Burma)',
    code: '+95',
    flagImagePos: '-1px -18px',
  },
  {
    iso: 'na',
    name: 'Namibia',
    code: '+264',
    flagImagePos: '-1px -2534px',
  },
  {
    iso: 'nr',
    name: 'Nauru',
    code: '+674',
    flagImagePos: '-1px -2330px',
  },
  {
    iso: 'np',
    name: 'Nepal',
    code: '+977',
    flagImagePos: '-1px -120px',
  },
  {
    iso: 'nl',
    name: 'Netherlands',
    code: '+31',
    flagImagePos: '-1px -1888px',
  },
  {
    iso: 'nc',
    name: 'New Caledonia',
    code: '+687',
    flagImagePos: '-1px -1650px',
  },
  {
    iso: 'nz',
    name: 'New Zealand',
    code: '+64',
    flagImagePos: '-1px -2024px',
  },
  {
    iso: 'ni',
    name: 'Nicaragua',
    code: '+505',
    flagImagePos: '-1px -171px',
  },
  {
    iso: 'ne',
    name: 'Niger',
    code: '+227',
    flagImagePos: '-1px -715px',
  },
  {
    iso: 'ng',
    name: 'Nigeria',
    code: '+234',
    flagImagePos: '-1px -3418px',
  },
  {
    iso: 'nu',
    name: 'Niue',
    code: '+683',
    flagImagePos: '-1px -2840px',
  },
  {
    iso: 'nf',
    name: 'Norfolk Island',
    code: '+672',
    flagImagePos: '-1px -256px',
  },
  {
    iso: 'kp',
    name: 'North Korea',
    code: '+850',
    flagImagePos: '-1px -2415px',
  },
  {
    iso: 'mk',
    name: 'North Macedonia',
    code: '+389',
    flagImagePos: '-1px -1769px',
  },
  {
    iso: 'mp',
    name: 'Northern Mariana Islands',
    code: '+1',
    flagImagePos: '-1px -919px',
  },
  {
    iso: 'no',
    name: 'Norway',
    code: '+47',
    flagImagePos: '-1px -1089px',
  },
  {
    iso: 'om',
    name: 'Oman',
    code: '+968',
    flagImagePos: '-1px -3384px',
  },
  {
    iso: 'pk',
    name: 'Pakistan',
    code: '+92',
    flagImagePos: '-1px -2772px',
  },
  {
    iso: 'pw',
    name: 'Palau',
    code: '+680',
    flagImagePos: '-1px -273px',
  },
  {
    iso: 'ps',
    name: 'Palestine',
    code: '+970',
    flagImagePos: '-1px -1548px',
  },
  {
    iso: 'pa',
    name: 'Panama',
    code: '+507',
    flagImagePos: '-1px -1106px',
  },
  {
    iso: 'pg',
    name: 'Papua New Guinea',
    code: '+675',
    flagImagePos: '-1px -1939px',
  },
  {
    iso: 'py',
    name: 'Paraguay',
    code: '+595',
    flagImagePos: '-1px -3231px',
  },
  {
    iso: 'pe',
    name: 'Peru',
    code: '+51',
    flagImagePos: '-1px -1225px',
  },
  {
    iso: 'ph',
    name: 'Philippines',
    code: '+63',
    flagImagePos: '-1px -2432px',
  },
  {
    iso: 'pl',
    name: 'Poland',
    code: '+48',
    flagImagePos: '-1px -1514px',
  },
  {
    iso: 'pt',
    name: 'Portugal',
    code: '+351',
    flagImagePos: '-1px -664px',
  },
  {
    iso: 'pr',
    name: 'Puerto Rico',
    code: '+1',
    flagImagePos: '-1px -596px',
  },
  {
    iso: 'qa',
    name: 'Qatar',
    code: '+974',
    flagImagePos: '-1px -579px',
  },
  {
    iso: 're',
    name: 'Réunion',
    code: '+262',
    flagImagePos: '-1px -324px',
  },
  {
    iso: 'ro',
    name: 'Romania',
    code: '+40',
    flagImagePos: '-1px -885px',
  },
  {
    iso: 'ru',
    name: 'Russia',
    code: '+7',
    flagImagePos: '-1px -868px',
  },
  {
    iso: 'rw',
    name: 'Rwanda',
    code: '+250',
    flagImagePos: '-1px -3673px',
  },
  {
    iso: 'ws',
    name: 'Samoa',
    code: '+685',
    flagImagePos: '-1px -3163px',
  },
  {
    iso: 'sm',
    name: 'San Marino',
    code: '+378',
    flagImagePos: '-1px -2908px',
  },
  {
    iso: 'st',
    name: 'São Tomé & Príncipe',
    code: '+239',
    flagImagePos: '-1px -3299px',
  },
  {
    iso: 'sa',
    name: 'Saudi Arabia',
    code: '+966',
    flagImagePos: '-1px -52px',
  },
  {
    iso: 'sn',
    name: 'Senegal',
    code: '+221',
    flagImagePos: '-1px -2925px',
  },
  {
    iso: 'rs',
    name: 'Serbia',
    code: '+381',
    flagImagePos: '-1px -3401px',
  },
  {
    iso: 'sc',
    name: 'Seychelles',
    code: '+248',
    flagImagePos: '-1px -1327px',
  },
  {
    iso: 'sl',
    name: 'Sierra Leone',
    code: '+232',
    flagImagePos: '-1px -970px',
  },
  {
    iso: 'sg',
    name: 'Singapore',
    code: '+65',
    flagImagePos: '-1px -35px',
  },
  {
    iso: 'sx',
    name: 'Sint Maarten',
    code: '+1',
    flagImagePos: '-1px -3826px',
  },
  {
    iso: 'sk',
    name: 'Slovakia',
    code: '+421',
    flagImagePos: '-1px -3044px',
  },
  {
    iso: 'si',
    name: 'Slovenia',
    code: '+386',
    flagImagePos: '-1px -1582px',
  },
  {
    iso: 'sb',
    name: 'Solomon Islands',
    code: '+677',
    flagImagePos: '-1px -1361px',
  },
  {
    iso: 'so',
    name: 'Somalia',
    code: '+252',
    flagImagePos: '-1px -1786px',
  },
  {
    iso: 'za',
    name: 'South Africa',
    code: '+27',
    flagImagePos: '-1px -3248px',
  },
  {
    iso: 'kr',
    name: 'South Korea',
    code: '+82',
    flagImagePos: '-1px -3078px',
  },
  {
    iso: 'ss',
    name: 'South Sudan',
    code: '+211',
    flagImagePos: '-1px -3775px',
  },
  {
    iso: 'es',
    name: 'Spain',
    code: '+34',
    flagImagePos: '-1px -1480px',
  },
  {
    iso: 'lk',
    name: 'Sri Lanka',
    code: '+94',
    flagImagePos: '-1px -3622px',
  },
  {
    iso: 'bl',
    name: 'St. Barthélemy',
    code: '+590',
    flagImagePos: '-1px -324px',
  },
  {
    iso: 'sh',
    name: 'St. Helena',
    code: '+290',
    flagImagePos: '-1px -630px',
  },
  {
    iso: 'kn',
    name: 'St. Kitts & Nevis',
    code: '+1',
    flagImagePos: '-1px -103px',
  },
  {
    iso: 'lc',
    name: 'St. Lucia',
    code: '+1',
    flagImagePos: '-1px -1837px',
  },
  {
    iso: 'mf',
    name: 'St. Martin',
    code: '+590',
    flagImagePos: '-1px -86px',
  },
  {
    iso: 'pm',
    name: 'St. Pierre & Miquelon',
    code: '+508',
    flagImagePos: '-1px -1378px',
  },
  {
    iso: 'vc',
    name: 'St. Vincent & Grenadines',
    code: '+1',
    flagImagePos: '-1px -3588px',
  },
  {
    iso: 'sd',
    name: 'Sudan',
    code: '+249',
    flagImagePos: '-1px -443px',
  },
  {
    iso: 'sr',
    name: 'Suriname',
    code: '+597',
    flagImagePos: '-1px -3656px',
  },
  {
    iso: 'se',
    name: 'Sweden',
    code: '+46',
    flagImagePos: '-1px -494px',
  },
  {
    iso: 'ch',
    name: 'Switzerland',
    code: '+41',
    flagImagePos: '-1px -1718px',
  },
  {
    iso: 'sy',
    name: 'Syria',
    code: '+963',
    flagImagePos: '-1px -2449px',
  },
  {
    iso: 'tw',
    name: 'Taiwan',
    code: '+886',
    flagImagePos: '-1px -647px',
  },
  {
    iso: 'tj',
    name: 'Tajikistan',
    code: '+992',
    flagImagePos: '-1px -222px',
  },
  {
    iso: 'tz',
    name: 'Tanzania',
    code: '+255',
    flagImagePos: '-1px -3146px',
  },
  {
    iso: 'th',
    name: 'Thailand',
    code: '+66',
    flagImagePos: '-1px -1242px',
  },
  {
    iso: 'tl',
    name: 'Timor-Leste',
    code: '+670',
    flagImagePos: '-1px -3843px',
  },
  {
    iso: 'tg',
    name: 'Togo',
    code: '+228',
    flagImagePos: '-1px -783px',
  },
  {
    iso: 'tk',
    name: 'Tokelau',
    code: '+690',
    flagImagePos: '-1px -3792px',
  },
  {
    iso: 'to',
    name: 'Tonga',
    code: '+676',
    flagImagePos: '-1px -1395px',
  },
  {
    iso: 'tt',
    name: 'Trinidad & Tobago',
    code: '+1',
    flagImagePos: '-1px -545px',
  },
  {
    iso: 'tn',
    name: 'Tunisia',
    code: '+216',
    flagImagePos: '-1px -698px',
  },
  {
    iso: 'tr',
    name: 'Turkey',
    code: '+90',
    flagImagePos: '-1px -2126px',
  },
  {
    iso: 'tm',
    name: 'Turkmenistan',
    code: '+993',
    flagImagePos: '-1px -3486px',
  },
  {
    iso: 'tc',
    name: 'Turks & Caicos Islands',
    code: '+1',
    flagImagePos: '-1px -1701px',
  },
  {
    iso: 'tv',
    name: 'Tuvalu',
    code: '+688',
    flagImagePos: '-1px -358px',
  },
  {
    iso: 'vi',
    name: 'U.S. Virgin Islands',
    code: '+1',
    flagImagePos: '-1px -2381px',
  },
  {
    iso: 'ug',
    name: 'Uganda',
    code: '+256',
    flagImagePos: '-1px -1497px',
  },
  {
    iso: 'ua',
    name: 'Ukraine',
    code: '+380',
    flagImagePos: '-1px -2721px',
  },
  {
    iso: 'ae',
    name: 'United Arab Emirates',
    code: '+971',
    flagImagePos: '-1px -3061px',
  },
  {
    iso: 'gb',
    name: 'United Kingdom',
    code: '+44',
    flagImagePos: '-1px -86px',
  },
  {
    iso: 'uy',
    name: 'Uruguay',
    code: '+598',
    flagImagePos: '-1px -3571px',
  },
  {
    iso: 'uz',
    name: 'Uzbekistan',
    code: '+998',
    flagImagePos: '-1px -1293px',
  },
  {
    iso: 'vu',
    name: 'Vanuatu',
    code: '+678',
    flagImagePos: '-1px -1633px',
  },
  {
    iso: 'va',
    name: 'Vatican City',
    code: '+39',
    flagImagePos: '-1px -3197px',
  },
  {
    iso: 've',
    name: 'Venezuela',
    code: '+58',
    flagImagePos: '-1px -1344px',
  },
  {
    iso: 'wf',
    name: 'Wallis & Futuna',
    code: '+681',
    flagImagePos: '-1px -324px',
  },
  {
    iso: 'ye',
    name: 'Yemen',
    code: '+967',
    flagImagePos: '-1px -2211px',
  },
  {
    iso: 'zm',
    name: 'Zambia',
    code: '+260',
    flagImagePos: '-1px -2109px',
  },
  {
    iso: 'zw',
    name: 'Zimbabwe',
    code: '+263',
    flagImagePos: '-1px -2789px',
  },
];

export function getCountryInfo(iso: string, field: 'name' | 'code' | 'flagImagePos' = 'name') {
  const ct = countries.find(e => e.iso.toLowerCase() === iso.toLowerCase());
  if (ct) {
    return ct[field];
  }
  return undefined
}
