<form [formGroup]="formData">
  <div class="relative flex">
    <mat-form-field class="flex-auto fuse-mat-no-subscript">
      <mat-label *ngIf="isLabel">{{ label }}</mat-label>
      <input [readonly]="isDisable" matInput [mask]="mask" type="tel" class="text-[16px]" [formControlName]="'phone'" />
      <mat-select [disabled]="isDisable" class="mr-1.5" [compareWith]="displayDial"
        (selectionChange)="dialSelectChange($event)" matPrefix>
        <mat-select-trigger>
          <span class="flex items-center">
            <span class="w-6 h-4 mr-1 overflow-hidden" [style.background]="
                  'url(\'/assets/images/flags/flags.png\')no-repeat 0 0'" [style.backgroundSize]="'24px 3876px'"
              [style.backgroundPosition]="flagImagePos"></span>
            <span class="sm:mx-0.5 font-medium text-default">{{
              dialCode
              }}</span>
          </span>
        </mat-select-trigger>
        <ng-container *ngFor="let country of filteredDial | async">
          <mat-option class="custom-phone-number" *ngIf="country.code"
            [ngClass]="{'active-dial': dialCode === country.code && flagImagePos === country.flagImagePos}"
            [value]="country">
            <span class="flex items-center">
              <span class="w-6 h-4 overflow-hidden" [style.background]="
                    'url(\'/assets/images/flags/flags.png\')no-repeat 0 0'" [style.backgroundSize]="'24px 3876px'"
                [style.backgroundPosition]="country.flagImagePos"></span>
              <span class="ml-2">{{ country.name }}</span>
              <span class="ml-2 font-medium">{{ country.code }}</span>
            </span>
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>
  </div>
</form>