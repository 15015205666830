import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberFormat'
})
export class NumberFormatPipe implements PipeTransform {

  transform(value: number, lang: string = 'en-US', options?: Intl.NumberFormatOptions): string {
    return new Intl.NumberFormat(lang, options).format(value);
  }
}