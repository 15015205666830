<!-- Shortcuts toggle -->
    <ng-container *ngIf="isScreenSmall; else tempMobile">
        <button
        class="invert"
        mat-icon-button
        (click)="openPanel()"
        #shortcutsOrigin>
        <mat-icon [svgIcon]="'icon_menu'"></mat-icon>
    </button>
    
    <!-- Shortcuts panel -->
    <ng-template #shortcutsPanel>
        <div class="fixed inset-0 sm:static sm:inset-auto flex flex-col sm:min-w-[480px] sm:w-[480px] sm:rounded-2xl overflow-hidden shadow-lg">
    
            <!-- Header -->
            <div class="flex shrink-0 items-center justify-between py-2 pr-4 pl-6 bg-primary text-on-primary">
                <div></div>
                <div class="flex items-center text-lg font-medium leading-10">
                    <span class="">{{businessName}}</span>
                </div>
                <div class="flex">
                    <button
                        mat-icon-button
                        (click)="closePanel()">
                        <mat-icon
                            class="icon-size-5 text-current"
                            [svgIcon]="'heroicons_solid:x-mark'"></mat-icon>
                    </button>
                </div>
            </div>
            <div class="relative flex flex-col flex-auto sm:max-h-132 -mb-px overflow-y-auto bg-card">
    
                <!-- View mode -->
                <!-- Shortcuts -->
                <ng-container *ngIf="supportRole && supportRole === 'CUSTOMER_SERVICE';else saTemp">
                    <!-- <navigation-sa (closeMenuChange)="closePanel()"></navigation-sa> -->
                </ng-container>
                <ng-template #saTemp>
                    <navigation [country]="country" (closeMenuChange)="closePanel()"></navigation>
                </ng-template>
            </div>
        </div>
    </ng-template>
  </ng-container>
  <ng-template #tempMobile>
    <ng-container *ngIf="supportRole && supportRole === 'CUSTOMER_SERVICE';else saTemp">
        <!-- <navigation-sa></navigation-sa> -->
    </ng-container>
    <ng-template #saTemp>
        <navigation [country]="country"></navigation>
    </ng-template>
  </ng-template>

