import { FuseNavigationItem } from '@fuse/components/navigation';

/* eslint-disable @typescript-eslint/naming-convention */
interface AppRoute {
  AUTH: {
    ROOT: string;
    LOGIN: string;
    SIGN_OUT: string;
    REGISTER: string;
    FORGOT_PASSWORD: string;
    RESET_PASSWORD: string;
    UNLOCK_SESSION: string;
    CONFIRIMATION_REQUIRED: string;
  };
  DASHBOARD: {
    HOME: string;
  };
  CUSTOMERS: string;
  NOT_FOUND: string;
  MAINTENANCE: string;
  USER_STAFF: string;
  REWARDS: string;
  GET_STARTED: string;
  SETTINGS: string;
  ACTIVITIES: string;
  ANNOUNCEMENTS: string;
  BUSINESS: string;
  SA_LOGIN: string;
  BUSINESS_SUPPORT: string;
  DASHBOARD_SA: string;
  CAMPAIGNS: string;
  APPROVE: string;
  OFFERS: string;
  OFFER: string;
  SERVICE_TOKENS: string;
  PAYMENT_GATEWAY: string;
  CUSTOMER_PAYMENT: string;
  CALLBACK: string;
  OFFERS_IMPROVE: string;
  OFFERS_CALENDAR: string;
  VOUCHERS: string;
  CALENDAR: string;
  CALENDAR_TEMPALTE: string;
}

export const appRoutes: AppRoute = {
  AUTH: {
    ROOT: 'auth',
    LOGIN: 'sign-in',
    REGISTER: 'sign-up',
    SIGN_OUT: 'sign-out',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset-password',
    UNLOCK_SESSION: 'unlock-session',
    CONFIRIMATION_REQUIRED: 'confirmation-required',
  },
  DASHBOARD: {
    HOME: 'dashboard',
  },
  ACTIVITIES: 'activities',
  CUSTOMERS: 'customers',
  NOT_FOUND: '404',
  MAINTENANCE: 'maintenance',
  USER_STAFF: 'users',
  REWARDS: 'rewards',
  GET_STARTED: 'get-started',
  SETTINGS: 'settings',
  ANNOUNCEMENTS: 'announcements',
  BUSINESS:'business',
  SA_LOGIN:'sa-login',
  BUSINESS_SUPPORT:'support',
  DASHBOARD_SA:'dashboard-sa',
  CAMPAIGNS:'campaigns',
  APPROVE:'approve',
  OFFERS:'offers',
  OFFER:'offer',
  SERVICE_TOKENS:'tokens',
  PAYMENT_GATEWAY: 'payment-gateway',
  CUSTOMER_PAYMENT: 'customer payment',
  CALLBACK:'callback',
  OFFERS_IMPROVE: 'offers-improve',
  OFFERS_CALENDAR: 'offers-calendar',
  VOUCHERS: 'vouchers',
  CALENDAR: 'calendar',
  CALENDAR_TEMPALTE: 'calendar-template'
};

export class AppRoutes {
  static get route(): AppRoute {
    return appRoutes;
  }

  static get stickyQrManager(): string {
    return `StickyQR - Manager`;
  }

  static get dashboard(): string {
    return `${appRoutes.DASHBOARD.HOME}`;
  }

  static get dashboardSa(): string {
    return `${appRoutes.DASHBOARD_SA}`;
  }

  static get activities(): string {
    return `${appRoutes.ACTIVITIES}`;
  }

  static get getStarted(): string {
    return `${appRoutes.GET_STARTED}`;
  }

  static get usersStaff(): string {
    return `${appRoutes.USER_STAFF}`;
  }

  static get customers(): string {
    return `${appRoutes.CUSTOMERS}`;
  }

  static get rewards(): string {
    return `${appRoutes.REWARDS}`;
  }

  static get settings(): string {
    return `${appRoutes.SETTINGS}`;
  }
  static get announcements(): string {
    return `${appRoutes.ANNOUNCEMENTS}`;
  }

  static get business(): string {
    return `${appRoutes.BUSINESS}`;
  }

  static get saLogin(): string {
    return `${appRoutes.SA_LOGIN}`;
  }
  static get business_support(): string {
    return `${appRoutes.BUSINESS_SUPPORT}`;
  }
  
  static get campaigns(): string {
    return `${appRoutes.CAMPAIGNS}`;
  }

  static get approve(): string {
    return `${appRoutes.APPROVE}`;
  }

  static get offers(): string {
    return `${appRoutes.OFFERS}`;
  }

  static get offer(): string {
    return `${appRoutes.OFFER}`;
  }

  static get offerImprove(): string {
    return `${appRoutes.OFFERS_IMPROVE}`;
  }

  static get offerCalendar(): string {
    return `${appRoutes.OFFERS_CALENDAR}`;
  }

  static get serviceTokens(): string {
    return `${appRoutes.SERVICE_TOKENS}`;
  }

  static get paymentGateway(): string {
    return `${appRoutes.PAYMENT_GATEWAY}`;
  }

  static get customerPayment(): string {
    return `${appRoutes.CUSTOMER_PAYMENT}`;
  }

  static get callback(): string {
    return `${appRoutes.CALLBACK}`;
  }

  // static get offersImprove(): string {
  //   return `${appRoutes.OFFERS_IMPROVE}`;
  // }

  static get vouchers(): string {
    return `${appRoutes.VOUCHERS}`;
  }

  static get calendar(): string {
    return `${appRoutes.CALENDAR}`;
  }
}
