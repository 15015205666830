import { Injectable } from '@angular/core';
import { createStore, withProps, select } from '@ngneat/elf';
import { persistState, localStorageStrategy } from '@ngneat/elf-persist-state';
import { AuthLogin } from './auth.model';
import { syncState } from 'elf-sync-state';

const store = createStore({ name: 'auth' }, withProps<AuthLogin>({}));
syncState(store);

const support_store = createStore({ name: 'auth_support' }, withProps<AuthLogin>({}));
syncState(support_store);

export const persist = persistState(store, {
  key: 'auth',
  storage: localStorageStrategy,
});
export const persistSupport = persistState(support_store, {
  key: 'auth_support',
  storage: localStorageStrategy,
});

@Injectable({ providedIn: 'root' })
export class AuthRepository {
  constructor() {
  }

  isLoggedIn$ = store.pipe(select((state) => !!state.accessToken));
  token$ = store.pipe(select((state) => state.accessToken));

  isSupportLoggedIn$ = support_store.pipe(select((state) => !!state.accessToken));
  tokenSupport$ = support_store.pipe(select((state) => state.accessToken));
  
  get accessSupportToken() {
    return this.support_store.getValue().accessToken;
  }
  get refreshSupportToken() {
    return this.support_store.getValue().refreshToken;
  }

  get accessToken() {
    return this.store.getValue().accessToken;
  }
  get refreshToken() {
    return this.store.getValue().refreshToken;
  }

  update(auth: AuthLogin) {
    store.update((state) => ({ ...state, ...auth }));
  }
  updateSupport(auth: AuthLogin) {
    support_store.update((state) => ({ ...state, ...auth }));
  }

  get store() {
    return store;
  }

  get userId() {
    return this.store.getValue().userId;
  }

  get userName() {
    return this.store.getValue().displayName;
  }

  get role() {
    return this.store.getValue()?.role;
  }
  get supportRole() {
    return this.store.getValue()?.supportRole;
  }
  get isSupported() {
    return this.support_store.getValue()?.isSupported;
  }

  get support_store() {
    return support_store
  }
}
